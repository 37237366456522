import { History } from 'history';
import { AxiosError, AxiosResponse } from 'axios';

import {
  getStoredAccessToken,
  getStoredSsoConfig,
  removeStoredAccessToken,
  removeStoredPaginationConfigPageSize,
  removeStoredRpmPaginationConfigPageSize,
  removeStoredUserLanguage,
  storeAccessToken,
  removeStoredTheme,
} from 'utils/localStorage';
import { config } from 'core/config';
import { store } from 'store';
import { hideLoader, showLoader } from 'reducers/loader';
import { removeUser } from 'reducers/session';
import { createZitadelAuth, ZitadelConfig } from '@zitadel/react';
import { SignInFormData } from 'pages/signIn/SignIn';
import { RawUser } from 'core/models/userModel';
import { apiWrapper, axiosApi, errorNotificationWith } from 'utils/api';
import getTimeZoneDict from 'core/service/timeZone.service';
import getSetting from 'core/service/setting.service';
import { changeLanguageTmp, changeUserLanguageTmp } from 'i18n';

interface LoginUserResponse {
  accessToken: string;
  callback: string | undefined;
  user: RawUser;
  message: string;
}

export const loginUser = async ({
  email,
  password,
  request,
}: SignInFormData): Promise<AxiosResponse<LoginUserResponse> | void> => {
  const success = async (): Promise<AxiosResponse<LoginUserResponse>> => {
    const response = await axiosApi().post<LoginUserResponse>(
      '/authentication',
      {
        strategy: 'local',
        email,
        password,
        request,
      },
    );
    const { accessToken, user, callback } = response.data;
    if (callback) {
      return response;
    }
    storeAccessToken(accessToken);
    if (user?.language != null) {
      changeUserLanguageTmp(user?.language);
    }
    // getTimeZoneDict();
    await getSetting(true);
    return response;
  };

  const error = (e: AxiosError): AxiosResponse | void => {
    if (e.response?.status === 401) {
      if (e.response.data.code === -1) {
        return e.response;
      }
      e.response.data.message = 'Invalid Email or Password';
      return e.response;
    }
    if (e.response?.status === 403) {
      e.response.data.message =
        'This account is deactivated. Please contact the VivaLNK support';
      return e.response;
    }
    errorNotificationWith({
      message: e.response?.data?.message || e.response?.statusText || e.message,
    });
    return e.response;
  };

  return apiWrapper<LoginUserResponse>({
    success,
    error,
  });
};

export const querySsoRedirect = (email: string): void => {
  axiosApi()
    .post('/sso/redirect', {
      email,
    })
    .then((response) => {
      if (response && response.data) {
        window.location.href = response.data;
      }
    });
};

export const quickLoginApi = async (
  vcloudToken: string,
): Promise<AxiosResponse<any> | void> => {
  const success = async (): Promise<AxiosResponse<any>> => {
    const response = await axiosApi().post<any>('/token/exchange', {
      vcloudToken,
    });
    const { token } = response.data?.data;
    storeAccessToken(token);
    return response;
  };
  return apiWrapper<any>({
    success,
  });
};

export const logoutUser = async (history?: History): Promise<void> => {
  store.dispatch(showLoader());
  removeStoredPaginationConfigPageSize();
  removeStoredRpmPaginationConfigPageSize();
  removeStoredAccessToken();
  removeStoredTheme();
  changeLanguageTmp();
  removeStoredUserLanguage();
  try {
    const ssoConfig = getStoredSsoConfig();
    if (ssoConfig.authority) {
      // redirect to SSO to end session
      const sso = createZitadelAuth(ssoConfig);
      const user = await sso.userManager.getUser();
      if (user != null) {
        await sso.signout();
      }
    }
  } catch (err) {
    // ignore error here to logout silent
  } finally {
    store.dispatch(removeUser());
    store.dispatch(hideLoader());
  }
};
