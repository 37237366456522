import { TablePaginationConfig } from 'antd';
import { ZitadelConfig } from '@zitadel/react';

const LS_TIMESTAMP = 'vivalnk_timestamp_';
const LS_AT = 'vivalnk_at';
const LS_SSO = 'vivalnk_sso_config';
const LS_SSO_SOURCE = 'vivalnk_sso_source';
const LS_LANGUAGE = 'vivalink_language';
const LS_USER_LANGUAGE = 'vivalink_user_language';
const LS_THEME = 'vivalnk_theme';
const LS_PAGINATION_CONFIG_PAGE_SIZE = 'vivalink_pagination_config_page_size';
const LS_RPM_PAGINATION_CONFIG_PAGE_SIZE =
  'vivalink_rpm_pagination_config_page_size';

export const getStoredAccessToken = (): string | null =>
  localStorage.getItem(LS_AT) || '';
export const storeAccessToken = (at: string): void =>
  localStorage.setItem(LS_AT, at);
export const removeStoredAccessToken = (): void =>
  localStorage.removeItem(LS_AT);

export const getStoredSource = (): string =>
  localStorage.getItem(LS_SSO_SOURCE) || '';
export const storeSource = (org: string): void =>
  localStorage.setItem(LS_SSO_SOURCE, org);
export const removeStoredSource = (): void =>
  localStorage.removeItem(LS_SSO_SOURCE);

export const getStoredSsoConfig = (): ZitadelConfig => {
  const config = localStorage.getItem(LS_SSO) || '';
  if (config !== '') {
    return JSON.parse(config) as ZitadelConfig;
  }
  return {};
};
export const storeSsoConfig = (config: ZitadelConfig): void =>
  localStorage.setItem(LS_SSO, JSON.stringify(config));
export const removeStoredSsoConfig = (): void =>
  localStorage.removeItem(LS_SSO);

export const getStoredTimestamp = (id: string): number =>
  Number(localStorage.getItem(LS_TIMESTAMP + id));
export const storeTimestamp = (id: string, timestamp: number): void => {
  localStorage.setItem(LS_TIMESTAMP + id, String(timestamp));
};

export const getStoredLanguage = (): string | null =>
  localStorage.getItem(LS_LANGUAGE);
export const setStoreLanguage = (at: string): void =>
  localStorage.setItem(LS_LANGUAGE, at);
export const removeStoredLanguage = (): void =>
  localStorage.removeItem(LS_LANGUAGE);
export const getStoredUserLanguage = (): string | null =>
  localStorage.getItem(LS_USER_LANGUAGE);
export const setStoreUserLanguage = (at?: string): void =>
  localStorage.setItem(
    LS_USER_LANGUAGE,
    at == null || at === '' ? 'en-US' : at,
  );
export const removeStoredUserLanguage = (): void =>
  localStorage.removeItem(LS_USER_LANGUAGE);
export const getStoredTheme = (): string =>
  localStorage.getItem(LS_THEME) || '{}';
export const storeTheme = (at: string): void =>
  localStorage.setItem(LS_THEME, at);
export const removeStoredTheme = (): void => localStorage.removeItem(LS_THEME);

export const getStoredPaginationConfigPageSize = (): string | null =>
  localStorage.getItem(LS_PAGINATION_CONFIG_PAGE_SIZE) || '10';
export const storePaginationConfigPageSize = (at: string): void =>
  localStorage.setItem(LS_PAGINATION_CONFIG_PAGE_SIZE, at);
export const removeStoredPaginationConfigPageSize = (): void =>
  localStorage.removeItem(LS_PAGINATION_CONFIG_PAGE_SIZE);
export const getStoredRpmPaginationConfigPageSize = (): string | null =>
  localStorage.getItem(LS_RPM_PAGINATION_CONFIG_PAGE_SIZE) || '6';
export const storeRpmPaginationConfigPageSize = (at: string): void =>
  localStorage.setItem(LS_RPM_PAGINATION_CONFIG_PAGE_SIZE, at);
export const removeStoredRpmPaginationConfigPageSize = (): void =>
  localStorage.removeItem(LS_RPM_PAGINATION_CONFIG_PAGE_SIZE);
