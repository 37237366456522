import { AxiosResponse } from 'axios';

import { apiWrapper, axiosApi } from 'utils/api';
import {
  ChangeProfileFormData,
  RawUser,
  User,
  UserModel,
} from 'core/models/userModel';
import { ChangePasswordFormData } from 'pages/profile/components/changePassword/ChangePassword';
import store from 'store';
import { setUser } from 'reducers/session';

export const profilePatch = async (
  profileDate: Partial<ChangeProfileFormData> | Partial<ChangePasswordFormData>,
  id?: string,
): Promise<AxiosResponse<RawUser> | void> => {
  const success = async (): Promise<AxiosResponse<RawUser>> => {
    return axiosApi().patch<RawUser>(`/currentUserInfo/${id}`, profileDate);
  };

  return apiWrapper<RawUser>({
    success,
  });
};

export const profileInfo = async (
  isLoading?: boolean,
): Promise<AxiosResponse<User> | void> => {
  const success = async (): Promise<AxiosResponse<User>> => {
    const response = await axiosApi().get<RawUser>(`/currentUserInfo`);
    store.dispatch(
      setUser({
        user: new UserModel(response.data),
      }),
    );
    return {
      ...response,
      data: new UserModel(response.data),
    };
  };

  return apiWrapper<User>({
    success,
    isLoading,
  });
};

export const profileSignatureUpload = async (
  base64: string,
  id?: string,
): Promise<AxiosResponse<RawUser> | void> => {
  const success = async (): Promise<AxiosResponse<RawUser>> => {
    return axiosApi().patch<RawUser>(`/currentUserInfo/${id}`, {
      signature: base64,
    });
  };

  return apiWrapper<RawUser>({
    success,
  });
};

export const updateWebVersion = async (
  id: string,
  isLoading?: boolean,
): Promise<AxiosResponse<RawUser> | void> => {
  const success = async (): Promise<AxiosResponse<RawUser>> => {
    return axiosApi().patch<RawUser>(`/currentUserInfo/${id}/updateWebVersion`);
  };

  return apiWrapper<RawUser>({
    success,
    isLoading,
  });
};

export default profilePatch;
