/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable consistent-return */
// eslint-disable-next-line no-use-before-define
import React, { ReactElement, useEffect, useState } from 'react';
import { Alert, Button, Card, Checkbox, Col, Form, Input, Row } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';

import {
  emailValidateMessage,
  requiredValidateMessage,
} from 'utils/validation';
import { loginUser, querySsoRedirect } from 'pages/signIn/signIn.service';
import { config } from 'core/config';
import { ApplicationState } from 'store/combineReducers';
import { statusCodesCheck } from 'utils/api';
import { passwordIconRender } from 'utils/helperRender';
import { useTranslation } from 'react-i18next';
import LocalesDropdown from 'layout/components/localesDropdown';
import { setStoreLanguage, getStoredSsoConfig } from 'utils/localStorage';
import { changeLanguageTmp } from 'i18n';
import getCurrentUser from 'routes/routes.service';
import LoginRedirect from 'pages/signIn/redirect';

const { Item } = Form;
const { Password } = Input;
const { restorePassword } = config.paths;

export interface SignInFormData {
  email: string;
  password: string;
  request: string | undefined;
}

const signInSchema = (): SchemaOf<SignInFormData> => {
  return yup.object().shape({
    email: yup
      .string()
      .required(requiredValidateMessage)
      .email(emailValidateMessage),
    password: yup.string().required(requiredValidateMessage),
    request: yup.string().optional(),
  });
};

interface SignInProps {
  request: string;
}
export default function SignIn({ request }: SignInProps): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuth, user } = useSelector(
    (state: ApplicationState) => state.session,
  );
  const ssoConfig = getStoredSsoConfig();
  const [responseErrorMessage, setResponseErrorMessage] = useState('');
  const searchParams = new URLSearchParams(history?.location?.search);

  useEffect(() => {
    const language = searchParams.get('language');
    if (language != null) {
      setStoreLanguage(language);
      changeLanguageTmp();
    }
  }, [history]);

  const { control, errors, handleSubmit } = useForm<SignInFormData>({
    resolver: yupResolver(signInSchema()),
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      request: '',
    },
  });

  function handleAlertClose(): void {
    setResponseErrorMessage('');
  }

  async function onSubmit(data: SignInFormData): Promise<void> {
    data.request = request || searchParams.get('authRequest') || '';
    const response = await loginUser(data);
    if (response && statusCodesCheck(response)) {
      if (response.data?.callback) {
        window.location.assign(response.data.callback);
        return;
      }
      // dispatch(setUser({ user: userModel }));
      await getCurrentUser();
    } else if (response && response.status === 301) {
      setTimeout(() => {
        window.location.replace('/');
      }, 500);
    } else {
      setResponseErrorMessage((response && response.data?.message) || '');
    }
  }

  if (isAuth) {
    return <LoginRedirect />;
  }

  const [checkboxValue, setCheckboxValue] = useState(true);
  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setCheckboxValue(e.target.checked);
  };

  return (
    <Row
      justify="center"
      align="middle"
      className="full-height-page with-header"
      style={{ marginTop: '-50px' }}
    >
      <Col style={{ width: '390px' }}>
        {!!responseErrorMessage && (
          <Alert
            message={responseErrorMessage}
            type="error"
            showIcon
            closable
            onClose={handleAlertClose}
          />
        )}
        <PageHeader title={t('signIn.title')} />
        <Card>
          <Form layout="vertical">
            <Item
              label={t('signIn.form.email.label')}
              validateStatus={errors.email && 'error'}
              help={errors.email?.message}
            >
              <Controller
                control={control}
                name="email"
                render={(field) => (
                  <Input
                    {...field} // eslint-disable-line react/jsx-props-no-spreading
                    placeholder={t('signIn.form.email.placeholder')}
                    onBlur={(e) => {
                      field.onBlur();
                      if (ssoConfig.authority) {
                        const email = e.target.value;
                        const schema = signInSchema();
                        schema.validateAt('email', { email }).then(() => {
                          querySsoRedirect(email);
                        });
                      }
                    }}
                  />
                )}
              />
            </Item>
            <Item
              label={t('signIn.form.password.label')}
              validateStatus={errors.password && 'error'}
              help={errors.password?.message}
            >
              <Controller
                as={
                  <Password
                    autoComplete="off"
                    placeholder={t('signIn.form.password.placeholder')}
                    iconRender={passwordIconRender}
                  />
                }
                control={control}
                name="password"
              />
            </Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
              onClick={handleSubmit(onSubmit)}
              disabled={!checkboxValue}
            >
              {t('signIn.button.signIn')}
            </Button>
          </Form>
          <br />
          <div
            style={{
              fontSize: '12px',
              color: '#595959',
              textAlign: 'center',
              paddingBottom: '10px',
            }}
          >
            <Checkbox onChange={onChangeCheckbox} checked={checkboxValue}>
              <span
                style={{
                  fontSize: '12px',
                  color: '#595959',
                  textAlign: 'center',
                  paddingBottom: '10px',
                }}
              >
                {t('signIn.checkbox.label')}
              </span>
            </Checkbox>
            <br />
            <a href="https://www.vivalink.com/terms-conditions" target="_blank">
              {t('signIn.aHref.termsConditions')}
            </a>
          </div>
          <Row justify="end">
            <Link to={restorePassword}>{t('signIn.button.forgot')}</Link>
          </Row>
        </Card>
        <div
          style={{
            paddingTop: '20px',
            textAlign: 'end',
            paddingRight: '5px',
            fontSize: '12px',
          }}
        >
          <a href="https://www.vivalink.com/terms-conditions" target="_blank">
            {t('signIn.aHref.termsConditions')}
          </a>
          <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <a href="https://www.vivalink.com/privacy-policy" target="_blank">
            {t('signIn.aHref.privacyPolicy')}
          </a>
        </div>
        <LocalesDropdown />
      </Col>
    </Row>
  );
}
