/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line no-use-before-define
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import './styles.less';
import { SvgComponent } from 'shared/components';
import { Dropdown, MenuProps, Modal } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';
import profilePatch from 'pages/profile/profile.service';
import getCurrentUser from 'routes/routes.service';
import { ApplicationState } from 'store/combineReducers';
import { useSelector } from 'react-redux';
import { CaretDownOutlined } from '@ant-design/icons';
import { successMessage } from 'utils/api';
import { useHistory } from 'react-router-dom';
import { config } from 'core/config';
import { MessageDataModel, WsLinkType, creatWs } from './utils/wsUtil';
import {
  addQueueListener,
  clearQueue,
  playAlertAudioQuery,
  removeQueueListener,
} from './utils/alertUtil';

const { dashboardRpmAlert } = config.paths;

export default function GlobalMessage(): ReactElement {
  const history = useHistory();
  const user = useSelector((state: ApplicationState) => state.session?.user);
  const [wsLinkSuccess, setWsLinkSuccess] = useState<boolean>();
  const [isAudio, setIsAudio] = useState<boolean>();
  const [data, setData] = useState<MessageDataModel>();
  const [alertPatientCount, setAlertPatientCount] = useState(0);
  const [alertCount, setAlertCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [lastAlarmTime, setLastAlarmTime] = useState<number>();
  const [noNewAlert, setNoNewAlert] = useState(true);
  const [flash, setFlase] = useState(false);

  const flashLoopTimer = useRef<any>();

  // 定时器
  const startTimer = () => {
    flashLoopTimer.current = setInterval(async () => {
      if (dayjs().diff(lastAlarmTime, 'second') > 1) {
        setNoNewAlert(true);
      }
    }, 1000 * 10);
  };

  // 定时器关闭
  const stopTimer = () => {
    clearInterval(flashLoopTimer.current);
  };

  useEffect(() => {
    if (lastAlarmTime != null) {
      setFlase(true);
      setNoNewAlert(false);
    }
    stopTimer();
    startTimer();
    return () => {
      stopTimer();
    };
  }, [lastAlarmTime]);

  const handleNewMessage = useCallback((data_?: MessageDataModel) => {
    setWsLinkSuccess(true);
    setData(data_);
    const alertPatientCount_ = data_?.alertPatientCount;
    const alertCount_ = data_?.alertCount;
    if (alertPatientCount_ != null) {
      setAlertPatientCount(alertPatientCount_);
    }
    if (data_?.isNewAlert != null) {
      if (data_?.isNewAlert) {
        setLastAlarmTime(dayjs().valueOf());
      }
    }
    if (alertCount_ != null) {
      setAlertCount(alertCount_);
      if (alertCount_ > 0) {
        setFlase(true);
      } else {
        setFlase(false);
        clearQueue();
      }
    }
    if (data_?.isAudio) {
      playAlertAudioQuery(data_?.audioQueue);
    }
  }, []);

  function updateWsLinkType(
    type: WsLinkType.MESSAGE | WsLinkType.ERROR | WsLinkType.CLOSE,
    data_?: MessageDataModel,
  ): void {
    if (type === WsLinkType.ERROR || type === WsLinkType.CLOSE) {
      setWsLinkSuccess(false);
      clearQueue();
    }
    if (type === WsLinkType.MESSAGE) {
      handleNewMessage(data_);
    }
  }

  const creatMonitor = useCallback(() => {
    creatWs({
      updateWsLinkType,
    });
    setWsLinkSuccess(true);
  }, []);

  const timer = useRef<any>();

  useEffect(() => {
    if (wsLinkSuccess != null && !wsLinkSuccess) {
      timer.current = setInterval(async () => {
        console.log('检测连接状态', dayjs().format('YYYY-MM-DD HH:mm:ss.SSS'));
        creatMonitor();
      }, 5000);
    } else {
      clearInterval(timer.current);
    }
  }, [wsLinkSuccess, timer, creatMonitor]);

  useEffect(() => {
    creatMonitor();
    addQueueListener();
    return () => {
      removeQueueListener();
    };
  }, []);

  const handleOpenChange = useCallback((nextOpen, info): void => {
    if (info.source === 'trigger' || nextOpen) {
      setOpen(nextOpen);
    }
  }, []);

  const updateUserAudio = useCallback(
    async (isAudio_: boolean) => {
      if (user != null) {
        // 更新用户信息
        const response = await profilePatch({ isAudio: isAudio_ }, user.id);
        if (response) {
          await getCurrentUser(true);
          let message = t('globalMessage.audioModal.message1');
          if (!isAudio_) {
            message = t('globalMessage.audioModal.message2');
          }
          successMessage(message);
        }
      }
    },
    [user],
  );

  const onAudioChange = useCallback(
    (checked: boolean) => {
      let title = t('globalMessage.audioModal.enableTitle');
      let content = (
        <div>
          <div style={{ paddingBottom: '14px' }}>
            {t('globalMessage.audioModal.enableContent1')}
          </div>
          <div>{t('globalMessage.audioModal.enableContent2')}</div>
          <div>{t('globalMessage.audioModal.enableContent3')}</div>
        </div>
      );
      if (!checked) {
        title = t('globalMessage.audioModal.disableTitle');
        content = (
          <div>
            <div style={{ paddingBottom: '14px' }}>
              {t('globalMessage.audioModal.disableContent1')}
            </div>
            <div>{t('globalMessage.audioModal.disableContent2')}</div>
          </div>
        );
      }
      Modal.confirm({
        title,
        icon: null,
        closable: true,
        width: 600,
        content,
        async onOk() {
          await updateUserAudio(checked);
          setIsAudio(checked);
        },
        onCancel() {
          // console.log('');
        },
      });
    },
    [user],
  );

  const items: MenuProps['items'] = [
    // {
    //   key: 'audio',
    //   label: (
    //     <div className="yahuikeji-aduio">
    //       <span className="label">{t('globalMessage.audio')}</span>
    //       <Switch onChange={onAudioChange} size="small" checked={isAudio} />
    //     </div>
    //   ),
    // },
  ];

  const handleJumpRpm = useCallback(() => {
    history.push(dashboardRpmAlert);
  }, []);

  const getClassName = useMemo(() => {
    if (alertCount > 0) {
      return 'yahuikeji-global-message-flase yahuikeji-global-message';
    }
    return 'yahuikeji-global-message-no-new-alert yahuikeji-global-message';
  }, [alertCount]);

  return (
    <div className={getClassName} onClick={handleJumpRpm}>
      <Dropdown
        menu={{ items }}
        placement="bottom"
        onOpenChange={handleOpenChange}
        open={open}
      >
        <div className="left">
          <div className="yahuikeji-icon">
            <SvgComponent
              iconName="globalMessage"
              width={20}
              height={20}
              className="anticon"
            />
          </div>
          <div className="yahuikeji-title">
            {alertCount <= 0
              ? t('globalMessage.noNewAlert')
              : t('globalMessage.newAlert')}
            {/* <CaretDownOutlined /> */}
          </div>
        </div>
      </Dropdown>

      <div className="right">
        <div className="title">
          <span className="label">{t('globalMessage.patient')}</span>
          <span className="value">{alertPatientCount}</span>
        </div>
        <div className="title">
          <span className="label">{t('globalMessage.alerts')}</span>
          <span className="value">{alertCount}</span>
        </div>
      </div>
    </div>
  );
}
