import { AxiosError, AxiosResponse } from 'axios';

import { apiWrapper, axiosApi, errorNotificationWith } from 'utils/api';
import { UserModel } from 'core/models';
import { RawUser, User } from 'core/models/userModel';
import { getStoredAccessToken } from 'utils/localStorage';
import { logoutUser } from 'pages/signIn';
import { setUser } from 'reducers/session';
import { store } from 'store';
import getTimeZoneDict from 'core/service/timeZone.service';
import getSetting from 'core/service/setting.service';
import { changeUserLanguageTmp } from 'i18n';

export const getCurrentUser = async (
  notQueGetTimeZone?: boolean,
): Promise<AxiosResponse<User> | void> => {
  const success = async (): Promise<void> => {
    const accessJWT = getStoredAccessToken()?.split('.')?.[1] || '';
    const { sub } = JSON.parse(atob(accessJWT));
    if (sub) {
      const response = await axiosApi().get<RawUser>(`/currentUserInfo/${sub}`);
      if (response) {
        store.dispatch(
          setUser({
            user: new UserModel(response.data),
          }),
        );
        if (response?.data?.language != null) {
          changeUserLanguageTmp(response.data.language);
        }
        if (notQueGetTimeZone == null || !notQueGetTimeZone) {
          getTimeZoneDict();
        }
        // getSetting();
      }
    } else {
      logoutUser();
    }
  };
  const error = (e: AxiosError): AxiosResponse<User> | void => {
    if (e.response?.status === 404) {
      logoutUser();
    } else if (e.response?.status === 401) {
      errorNotificationWith({ message: e?.response?.data?.message });
      setTimeout(logoutUser, 1000);
    }
    return e.response;
  };

  return apiWrapper<User>({
    success,
    error,
  });
};

export default getCurrentUser;
