// eslint-disable-next-line no-use-before-define
import React, { ReactElement, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { config } from 'core/config';
import { baseUrlUser, successNotificationWith } from 'utils/api';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/combineReducers';
import { getStoredSource } from 'utils/localStorage';

export default function LoginRedirect(): ReactElement {
  const sourcePath = getStoredSource();
  const isDownload = sourcePath && sourcePath.startsWith(config.paths.download);
  const { user } = useSelector((state: ApplicationState) => state.session);
  useEffect(() => {
    if (isDownload || !user?.email) {
      return;
    }
    successNotificationWith({ message: `Hello, ${user?.email}` });
    const pageIds = [
      'Program Manager',
      'Physician',
      'Technician',
      'Admin',
      'VivaLNK Admin',
      'Clinical Trial Coordinator',
    ];
    let resultUser: any = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < pageIds.length; i++) {
      resultUser = resultUser.concat(
        user?.roles?.filter((item: any) => item.name === pageIds[i]),
      );
    }
  }, [user, isDownload]);
  // redirect source
  if (!user || !user.roles) {
    return <div />;
  }
  if (sourcePath) {
    return <Redirect to={sourcePath} />;
  }
  // redirect user
  return <Redirect to={baseUrlUser(user?.roles[0].name)} />;
}
