import { ClinicModel, UserModelBase } from 'core/models';
import { Clinic, RawClinic } from 'core/models/clinicModel';
import { UserBase } from 'core/models/userModelBase';
import { RawRole, Role, RoleModel, RolesEnum } from './roleModel';

// eslint-disable-next-line no-shadow
export enum UserStatusEnum {
  enabled = 'enabled',
  disabled = 'disabled',
}

export interface ChangeProfileFormData {
  signatureAuthorize?: boolean;
  language?: string;
  isAudio?: boolean;
}

export interface WebVersionInfo {
  readStatus: boolean;
  time: string;
  version: string;
  url: string;
}

export interface User extends UserBase {
  roles?: any;
  clinic: Clinic;
  status: UserStatusEnum;
  phone?: string;
  position?: string;
  site?: string;
  siteName?: string;
  assignedPatient?: boolean;
  isVivaLNKAdmin?: boolean;
  isProgramManager?: boolean;
  isAdmin?: boolean;
  isPhysician?: boolean;
  isTechnician?: boolean;
  isResearcher?: boolean;
  language?: string;
  signature?: string;
  signatureAuthorize?: boolean;
  webVersionInfo?: WebVersionInfo;
  isAudio: boolean;
}

export interface RawUser {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  roles?: any;
  clinic: RawClinic;
  status: UserStatusEnum;
  phone?: string;
  position?: string;
  createdAt: string;
  updatedAt: string;
  site?: string;
  siteName?: string;
  assignedPatient?: boolean;
  language?: string;
  signature?: string;
  signatureAuthorize?: boolean;
  webVersionInfo?: WebVersionInfo;
  isAudio: boolean;
}

export interface UserRoleProps {
  userRole?: RolesEnum;
}

export class UserModel extends UserModelBase implements User {
  roles?: any;

  clinic: Clinic;

  status: UserStatusEnum = UserStatusEnum.disabled;

  phone?: string;

  position?: string;

  site?: string;

  siteName?: string;

  assignedPatient?: boolean;

  isVivaLNKAdmin?: boolean;

  isProgramManager?: boolean;

  isAdmin?: boolean;

  isPhysician?: boolean;

  isTechnician?: boolean;

  isResearcher?: boolean;

  language?: string;

  signature?: string;

  signatureAuthorize?: boolean;

  webVersionInfo?: WebVersionInfo;

  isAudio: boolean;

  constructor(data: RawUser) {
    super(data);
    // console.log(data);
    this.id = data._id;
    this.roles = data.roles;
    this.clinic = data.clinic && new ClinicModel(data.clinic);
    this.status = data.status;
    this.phone = data?.phone;
    this.position = data?.position;
    this.site = data?.site;
    this.siteName = data.siteName;
    this.assignedPatient = data.assignedPatient;
    const roleNameList = this?.roles?.map((role: { name: string }) => {
      return role.name;
    });
    if (roleNameList != null) {
      if (roleNameList?.includes(RolesEnum.VivaLNKAdmin)) {
        this.isVivaLNKAdmin = true;
      }
      if (roleNameList?.includes(RolesEnum.ProgramManager)) {
        this.isProgramManager = true;
      }
      if (roleNameList?.includes(RolesEnum.Admin)) {
        this.isAdmin = true;
      }
      if (roleNameList?.includes(RolesEnum.Physician)) {
        this.isPhysician = true;
      }
      if (roleNameList?.includes(RolesEnum.Technician)) {
        this.isTechnician = true;
      }
      if (roleNameList?.includes(RolesEnum.Researcher)) {
        this.isResearcher = true;
      }
    }
    this.language = data.language;
    this.signature = data.signature;
    this.signatureAuthorize = data.signatureAuthorize;
    this.webVersionInfo = data.webVersionInfo;
    this.isAudio = data.isAudio;
  }
}
