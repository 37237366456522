/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */

export enum QueueOperation {
  ENQUEUE = 'enqueue',
  DEQUEUE = 'dequeue',
  EMPTY = 'empty',
}

export interface QueueItem {
  type?: number;
}

export type Listener<T> = (
  queue: T[],
  operation: QueueOperation,
  item?: T,
) => void;

export class ObservableQueue<T> {
  private queue: T[] = [];

  private listeners: Listener<T>[] = [];

  // 添加元素到队列
  enqueue(item: T): void {
    this.queue.push(item);
    this.notifyListeners(QueueOperation.ENQUEUE, item);
  }

  // 批量添加元素到队列
  enqueueAll(items: T[]): void {
    this.queue.push(...items);
    this.notifyListeners(QueueOperation.ENQUEUE);
  }

  // 查看队列中的第一个元素但不移除它
  peek(): T | undefined {
    return this.queue.length > 0 ? this.queue[0] : undefined;
  }

  // 从队列中移除第一个元素
  removeFirst(): T | undefined {
    if (this.queue.length === 0) {
      this.notifyListeners(QueueOperation.EMPTY);
      return undefined;
    }
    const item = this.queue.shift();
    this.notifyListeners(QueueOperation.DEQUEUE, item);
    return item;
  }

  // 清空队列
  clear(): void {
    this.queue = [];
    this.notifyListeners(QueueOperation.EMPTY);
  }

  // 获取队列的当前状态
  getItems(): T[] {
    return this.queue;
  }

  // 注册监听器
  addListener(listener: Listener<T>): void {
    this.listeners.push(listener);
  }

  // 移除监听器
  removeListener(listener: Listener<T>): void {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  // 通知所有监听器
  private notifyListeners(operation: QueueOperation, item?: T): void {
    this.listeners.forEach((listener) => listener(this.queue, operation, item));
  }
}
