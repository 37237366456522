/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line no-use-before-define
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Avatar,
  Dropdown,
  Menu as MenuAnt,
  MenuProps,
  Modal,
  Image as AntImage,
} from 'antd';
import { removeUser, setUser } from 'reducers/session';
import { removeStoredAccessToken } from 'utils/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import { History } from 'history';
import { ApplicationState } from 'store/combineReducers';
import { config } from 'core/config';
import { baseUrlUser } from 'utils/api';
import uniqid from 'uniqid';
import { useTranslation } from 'react-i18next';
import getCurrentUser from 'routes/routes.service';
import profilePatch, { updateWebVersion } from 'pages/profile/profile.service';
import { changeLanguageTmp, changeUserLanguageTmp } from 'i18n';

import './styles.less';
import { WebVersionInfo } from 'core/models/userModel';
import { logoutUser } from 'pages/signIn';

const { Item } = MenuAnt;
const { profile } = config.paths;

const logoDefault = `/static/images/version-logo.jpeg`;

function AvatarDropdownItems(): ReactElement {
  const dispatch = useDispatch();
  const history: History = useHistory();
  const handleLogout = useCallback((): void => {
    dispatch(removeUser());
    removeStoredAccessToken();
    history.push(baseUrlUser());
  }, [dispatch, history]);

  const menuAntItems = [
    { label: <Link to={profile}>Profile</Link>, key: uniqid() },
    {
      label: <div onClick={handleLogout}>Logout</div>,
      key: uniqid(),
    },
  ];

  return <MenuAnt items={menuAntItems} />;
}

export default function AvatarDropdown(): ReactElement {
  const { t } = useTranslation();
  const [isVersionModalOpen, setIsVersionModalOpen] = useState(false);

  const { user } = useSelector((state: ApplicationState) => state.session);
  const { firstName, lastName, email } = user || {};
  const [versionInfo, setVersionInfo] = useState<WebVersionInfo>();

  const dispatch = useDispatch();
  const history: History = useHistory();
  const handleLogout = useCallback(async (): Promise<void> => {
    await logoutUser();
  }, []);

  const handleAbout = useCallback(async (): Promise<void> => {
    if (user?.id != null) {
      setIsVersionModalOpen(true);
      const res = await updateWebVersion(user.id, false);
      if (res) {
        if (versionInfo != null) {
          const versionInfo_ = { ...versionInfo };
          versionInfo_.readStatus = true;
          setVersionInfo(versionInfo_);
        }
      }
    }
  }, [dispatch, user, versionInfo]);

  const hangeLanguage = useCallback(
    async (e): Promise<void> => {
      changeUserLanguageTmp(e.key);
      // 更新用户语言信息
      const response = await profilePatch({ language: e.key }, user?.id);
      if (response) {
        await getCurrentUser();
        window.location.reload();
      }
    },
    [user],
  );

  useEffect(() => {
    if (user != null && user.webVersionInfo != null) {
      setVersionInfo(user.webVersionInfo);
    }
  }, [user]);

  const versionReadStatus = useMemo(() => {
    if (versionInfo != null) {
      return versionInfo.readStatus;
    }
    return undefined;
  }, [versionInfo]);

  const menuAntItems: MenuProps['items'] = [
    {
      label: <Link to={profile}>{t('layoutHeader.menuAntItems.profile')}</Link>,
      key: uniqid(),
    },
    {
      label: (
        <Link to={profile}>{t('layoutHeader.menuAntItems.language')}</Link>
      ),
      key: uniqid(),
      children: [
        {
          key: 'en-US',
          onClick: hangeLanguage,
          label: <div>English</div>,
        },
        {
          key: 'zh-CN',
          onClick: hangeLanguage,
          label: <div>简体中文</div>,
        },
      ],
    },
    {
      label: (
        <div
          onClick={handleAbout}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {t('layoutHeader.menuAntItems.about')}
          {versionReadStatus != null && !versionReadStatus && (
            <span style={{ color: 'red', marginLeft: '10px' }}>(New)</span>
          )}
        </div>
      ),
      key: uniqid(),
    },
    {
      label: (
        <div onClick={handleLogout}>
          {t('layoutHeader.menuAntItems.logout')}
        </div>
      ),
      key: uniqid(),
    },
  ];

  const handleVersionCancel = useCallback(() => {
    setIsVersionModalOpen(false);
  }, []);

  return (
    <>
      <Dropdown menu={{ items: menuAntItems }} placement="bottom" arrow>
        <div className="yahuikeji-profile">
          {versionReadStatus != null && !versionReadStatus && (
            <div className="readStatus" />
          )}
          <Link to={profile}>
            <Avatar style={{ marginRight: '10px' }}>
              {firstName?.[0] || email?.[0]}
              {lastName?.[0] || email?.[1]}
            </Avatar>
            {!!firstName && !!lastName ? `${firstName} ${lastName}` : email}
          </Link>
        </div>
      </Dropdown>
      <Modal
        open={isVersionModalOpen}
        onCancel={handleVersionCancel}
        footer={null}
        className="version-info-modal"
        width={350}
      >
        <div className="version-info">
          <div className="logo">
            <AntImage src={logoDefault} preview={false} />
          </div>
          <div className="v-u">
            <div className="version">
              <span className="label">Version:</span>
              <a href={versionInfo?.url}>{versionInfo?.version}</a>
            </div>
            <div className="update-time">
              <span className="label">Updated:</span>
              <span>{versionInfo?.time}</span>
            </div>
          </div>
          <div className="copyright">
            Copyright © 2024 VivaLNK, Inc. All Rights Reserved.
          </div>
        </div>
      </Modal>
    </>
  );
}
