/* eslint-disable import/prefer-default-export */
/* eslint-disable no-use-before-define */

import { Listener, ObservableQueue, QueueItem, QueueOperation } from './queue';

let audioInstance: HTMLAudioElement | null = null;
let isPlaying = false;
let isStopping = false;
let isError = false;

async function play(type?: number): Promise<void> {
  if (isPlaying || isStopping || audioInstance != null) {
    return; // 如果已经在播放或准备播放，则不执行新的播放请求
  }
  isPlaying = true;
  // 创建新的音频对象并播放
  let audioSrc = '/static/aduios/alarm.mp3';
  if (type === 1) {
    audioSrc = '/static/aduios/2-Moderate.mp3';
  } else if (type === 2) {
    audioSrc = '/static/aduios/4-LossOfConnectivity.mp3';
  }
  audioInstance = new Audio(audioSrc); // 创建音频对象

  try {
    await audioInstance.play();
    // 确保 audioInstance 不是 null 后再添加事件监听器
    audioInstance.addEventListener('ended', handleAudioEnded);
    audioInstance.addEventListener('error', handleAudioError);
  } catch (error) {
    console.error('音频播放出错: ', error);
    isError = true;
    clearQueue();
    resetAudio();
  }
}

function handleAudioEnded(): void {
  console.log('音频播放结束');
  resetAudio();
  // 播放结束后处理下一个队列项
  processQueue();
}

function handleAudioError(event: Event): void {
  console.error('音频加载出错: ', event);
  isError = true;
  clearQueue();
  resetAudio();
}

function resetAudio(): void {
  if (audioInstance) {
    audioInstance.removeEventListener('ended', handleAudioEnded);
    audioInstance.removeEventListener('error', handleAudioError);
    audioInstance = null;
  }
  isPlaying = false;
  isStopping = false;
}

async function stop(): Promise<void> {
  if (audioInstance !== null) {
    isStopping = true;
    // 暂停播放并重置
    audioInstance.pause();
    audioInstance.currentTime = 0;
    resetAudio();
  }
}

// 创建一个队列实例
const queue = new ObservableQueue<QueueItem>();
const listener: Listener<QueueItem> = (
  queue_: QueueItem[],
  operation: QueueOperation,
  item?: QueueItem,
) => {
  // 每次队列变化时，处理队列中的下一个元素
  processQueue();
};

// 从队列中获取第一个元素并执行，之后移除，然后重新加入
async function processQueue(): Promise<void> {
  if (isPlaying || isStopping) {
    return; // 如果正在播放或停止中，则不处理队列
  }
  const item = queue.peek();
  const allItems = queue.getItems();
  // console.log('队列的全部音频数据', allItems);
  // console.log('准备播放的音频', item);
  if (item) {
    await play(item.type);
    queue.removeFirst();
    if (!isError) {
      queue.enqueue({ type: item.type });
    }
  }
}

let debounceTimeout: NodeJS.Timeout | null = null;

export function playAlertAudioQuery(audioQueue?: number[]): void {
  // 清除之前的防抖超时，确保只处理最后一次的推送数据
  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
  }

  // 设置新的防抖超时
  debounceTimeout = setTimeout(() => {
    // 清空队列
    clearQueue();
    // 暂停正在播放的
    stop();
    isError = false;
    if (audioQueue != null && audioQueue.length > 0) {
      const enqueueItems = audioQueue.map((d) => {
        const it: QueueItem = { type: d };
        return it;
      });
      queue.enqueueAll(enqueueItems);
      // console.log('新推过来带播放音频数据', enqueueItems);
    }

    // 清除防抖超时
    debounceTimeout = null;
  }, 1000); // 设置防抖时间为1秒
}

export function clearQueue(): void {
  // 清空队列
  queue.clear();
}

export function addQueueListener(): void {
  isError = false;
  // 添加监听器
  queue.addListener(listener);
}

export function removeQueueListener(): void {
  isError = true;
  // 移除监听器
  queue.removeListener(listener);
  clearQueue();
  stop();
}
