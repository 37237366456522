// import { EventRule } from 'pages/monitoringProtocolsManagement/components/monitoringProtocolsForm/MonitoringProtocolForm';

import { EcgEventType } from '../eventECGTypeModel';
import { Role } from '../roleModel';

export interface NotificationType {
  id: string;
  name: string;
}

export interface RuleTemplate {
  emailTemplate: string;
  thirdPartyEmailTemplate: string;
  smsTemplate: string;
  thirdPartySmsTemplate: string;
}

export interface RuleCommon {
  _id?: string;
  notifyType?: string[];
  notifyInterval?: number;
  emailTemplate?: string;
  smsTemplate?: string;
  thirdPartyEmailTemplate?: string;
  thirdPartySmsTemplate?: string;
}

export interface EventRule extends RuleCommon {
  key: string;
  eventType: string | EcgEventType;
  color: string;
  setFlag: boolean | string;
  notifyRoles?: Role[] | string[];
}

export interface HeartRateRule extends RuleCommon {
  notifyRoles?: Role[] | string[];
  beats: string | number;
  color: string;
  duration: string | number;
  durationType: string;
  key: string;
  ruleType: string;
  setFlag: boolean | string;
}

export type HeartRateRuleTemplate = RuleTemplate;

export interface ECGInactivityAlertRule extends RuleCommon {
  key: string;
  color: string;
  duration: string | number;
  setFlag: boolean | string;
  notifyRoles?: Role[] | string[];
}

export interface SpO2InactivityAlertRule extends RuleCommon {
  key: string;
  color: string;
  duration: string | number;
  setFlag: boolean | string;
  notifyRoles?: Role[] | string[];
}

export interface TemperatureInactivityAlertRule extends RuleCommon {
  key: string;
  color: string;
  duration: string | number;
  setFlag: boolean | string;
  notifyRoles?: Role[] | string[];
}

export interface BPInactivityAlertRule extends RuleCommon {
  key: string;
  color: string;
  count: string | number;
  duration: string | number;
  setFlag: boolean | string;
  notifyRoles?: Role[] | string[];
}

export interface MultipleEventsRule extends RuleCommon {
  key: string;
  eventType?: string | EcgEventType;
  count: string | number;
  durationType: string;
  duration: string | number;
  color: string;
  setFlag: boolean | string;
  notifyRoles?: Role[] | string[];
}

export interface BPRule extends RuleCommon {
  notifyRoles?: Role[] | string[];
  color: string;
  value: string | number;
  type: string;
  key: string;
  ruleType: string;
  setFlag: boolean | string;
}

export interface TemperatureRule extends RuleCommon {
  notifyRoles?: Role[] | string[];
  value: string | number;
  color: string;
  duration: string | number;
  durationType: string;
  key: string;
  ruleType: string;
  setFlag: boolean | string;
}

export interface SpO2Rule extends RuleCommon {
  notifyRoles?: Role[] | string[];
  value: string | number;
  color: string;
  key: string;
  ruleType: string;
  setFlag: boolean | string;
}

export interface CGMTimeOutOfRangeRuleValue {
  param1condition: string;
  param1value?: string | number;
  param2condition: string;
  param2value?: string | number;
}

export interface CGMTimeOutOfRangeRule extends RuleCommon {
  key: string;
  type: string;
  value: CGMTimeOutOfRangeRuleValue;
  proportion: string | number;
  durationType: string;
  duration: string | number;
  color: string;
  setFlag: boolean | string;
  notifyRoles?: Role[] | string[];
}

export interface CGMBloodGlucoseLevelRule extends RuleCommon {
  key: string;
  type: string;
  value: string | number;
  durationType: string;
  duration: string | number;
  color: string;
  setFlag: boolean | string;
  notifyRoles?: Role[] | string[];
}

export interface CGMMultipleEventsRule extends RuleCommon {
  key: string;
  eventType?: string | EcgEventType;
  count: string | number;
  durationType: string;
  duration: string | number;
  color: string;
  setFlag: boolean | string;
  notifyRoles?: Role[] | string[];
}

export interface RPMVitalAlertRuleValue {
  param1condition: string;
  param1value?: string | number;
  param2condition: string;
  param2value?: string | number;
}

export interface RPMVitalAlertRule extends RuleCommon {
  key: string;
  rowKey?: number;
  type?: string;
  alertThresholdAndAveragingDuration?: string;
  value?: CGMTimeOutOfRangeRuleValue;
  averagingDuration?: number;
  slidingLength?: number;
  alertColorFlag?: string;
  alertSeverity?: string;
  notifyRoles?: Role[] | string[];
  children?: RPMVitalAlertRule[];
}

export interface RPMDeviceInactivityAlertRule extends RuleCommon {
  key: string;
  type: string;
  duration: string | number;
  notifyRoles?: Role[] | string[];
}

export interface RPMDeviceLowBatteryAlertRule extends RuleCommon {
  key: string;
  type: string;
  lowValue: string | number;
  mediumValue: string | number;
  notifyRoles?: Role[] | string[];
}

export interface MonitoringProtocol {
  id: string;
  name: string;
  notifyInterval: number;
  eventRules: EventRule[];
  heartRateRules: HeartRateRule[];
  multipleEventsRules?: MultipleEventsRule[] | undefined;
  ecgInactivityAlertRules: ECGInactivityAlertRule[];
  spo2InactivityAlertRules: SpO2InactivityAlertRule[];
  temperatureInactivityAlertRules: TemperatureInactivityAlertRule[];
  bpInactivityAlertRules: BPInactivityAlertRule[];
  bpRules: BPRule[];
  temperatureRules: TemperatureRule[];
  spo2Rules: SpO2Rule[];
  cgmTimeOutOfRangeRules: CGMTimeOutOfRangeRule[];
  cgmBloodGlucoseLevelRules: CGMBloodGlucoseLevelRule[];
  cgmMultipleEventsRules: CGMMultipleEventsRule[];
  rpmVitalAlertRules: RPMVitalAlertRule[];
  rpmDeviceInactivityAlertRules: RPMDeviceInactivityAlertRule[];
  rpmDeviceLowBatteryAlertRules: RPMDeviceLowBatteryAlertRule[];
  parentId: string;
  customStatus: boolean;
  patientId: string;
  customStatusDisabled: boolean;
  eventRuleTemplate: RuleTemplate;
  heartRateRuleTemplate: RuleTemplate;
  multipleEventsRuleTemplate: RuleTemplate;
  ecgInactivityAlertRuleTemplate: RuleTemplate;
  spo2InactivityAlertRuleTemplate: RuleTemplate;
  temperatureInactivityAlertRuleTemplate: RuleTemplate;
  bpInactivityAlertRuleTemplate: RuleTemplate;
  bpRuleTemplate: RuleTemplate;
  temperatureRuleTemplate: RuleTemplate;
  spo2RuleTemplate: RuleTemplate;
  cgmTimeOutOfRangeRuleTemplate: RuleTemplate;
  cgmBloodGlucoseLevelRuleTemplate: RuleTemplate;
  cgmMultipleEventsRuleTemplate: RuleTemplate;
  rpmVitalAlertRuleTemplate: RuleTemplate;
  rpmDeviceInactivityAlertRuleTemplate: RuleTemplate;
  rpmDeviceLowBatteryAlertRuleTemplate: RuleTemplate;
  isQuickPatientAssignment: boolean;
}

export interface RawMonitoringProtocol {
  _id: string;
  name: string;
  notifyInterval: number;
  eventRules: EventRule[];
  heartRateRules: HeartRateRule[];
  multipleEventsRules?: MultipleEventsRule[] | undefined;
  ecgInactivityAlertRules: ECGInactivityAlertRule[];
  spo2InactivityAlertRules: SpO2InactivityAlertRule[];
  temperatureInactivityAlertRules: TemperatureInactivityAlertRule[];
  bpInactivityAlertRules: BPInactivityAlertRule[];
  bpRules: BPRule[];
  temperatureRules: TemperatureRule[];
  spo2Rules: SpO2Rule[];
  cgmTimeOutOfRangeRules: CGMTimeOutOfRangeRule[];
  cgmBloodGlucoseLevelRules: CGMBloodGlucoseLevelRule[];
  cgmMultipleEventsRules: CGMMultipleEventsRule[];
  rpmVitalAlertRules: RPMVitalAlertRule[];
  rpmDeviceInactivityAlertRules: RPMDeviceInactivityAlertRule[];
  rpmDeviceLowBatteryAlertRules: RPMDeviceLowBatteryAlertRule[];
  customStatus: boolean;
  parentId: string;
  patientId: string;
  customStatusDisabled: boolean;
  eventRuleTemplate: RuleTemplate;
  heartRateRuleTemplate: RuleTemplate;
  multipleEventsRuleTemplate: RuleTemplate;
  ecgInactivityAlertRuleTemplate: RuleTemplate;
  spo2InactivityAlertRuleTemplate: RuleTemplate;
  temperatureInactivityAlertRuleTemplate: RuleTemplate;
  bpInactivityAlertRuleTemplate: RuleTemplate;
  bpRuleTemplate: RuleTemplate;
  temperatureRuleTemplate: RuleTemplate;
  spo2RuleTemplate: RuleTemplate;
  cgmTimeOutOfRangeRuleTemplate: RuleTemplate;
  cgmBloodGlucoseLevelRuleTemplate: RuleTemplate;
  cgmMultipleEventsRuleTemplate: RuleTemplate;
  rpmVitalAlertRuleTemplate: RuleTemplate;
  rpmDeviceInactivityAlertRuleTemplate: RuleTemplate;
  rpmDeviceLowBatteryAlertRuleTemplate: RuleTemplate;
  isQuickPatientAssignment: boolean;
}

export const MONITORING_PROTOCOL_TITLE = 'Monitoring Protocol';

export class MonitoringProtocolModel implements MonitoringProtocol {
  id: string;

  name: string;

  notifyInterval: number;

  eventRules: EventRule[];

  heartRateRules: HeartRateRule[];

  ecgInactivityAlertRules: ECGInactivityAlertRule[];

  spo2InactivityAlertRules: SpO2InactivityAlertRule[];

  temperatureInactivityAlertRules: TemperatureInactivityAlertRule[];

  bpInactivityAlertRules: BPInactivityAlertRule[];

  multipleEventsRules?: MultipleEventsRule[] | undefined;

  bpRules: BPRule[];

  temperatureRules: TemperatureRule[];

  spo2Rules: SpO2Rule[];

  cgmTimeOutOfRangeRules: CGMTimeOutOfRangeRule[];

  cgmBloodGlucoseLevelRules: CGMBloodGlucoseLevelRule[];

  cgmMultipleEventsRules: CGMMultipleEventsRule[];

  rpmVitalAlertRules: RPMVitalAlertRule[];

  rpmDeviceInactivityAlertRules: RPMDeviceInactivityAlertRule[];

  rpmDeviceLowBatteryAlertRules: RPMDeviceLowBatteryAlertRule[];

  customStatus: boolean;

  parentId: string;

  patientId: string;

  customStatusDisabled: boolean;

  eventRuleTemplate: RuleTemplate;

  heartRateRuleTemplate: RuleTemplate;

  multipleEventsRuleTemplate: RuleTemplate;

  ecgInactivityAlertRuleTemplate: RuleTemplate;

  spo2InactivityAlertRuleTemplate: RuleTemplate;

  temperatureInactivityAlertRuleTemplate: RuleTemplate;

  bpInactivityAlertRuleTemplate: RuleTemplate;

  bpRuleTemplate: RuleTemplate;

  temperatureRuleTemplate: RuleTemplate;

  spo2RuleTemplate: RuleTemplate;

  cgmTimeOutOfRangeRuleTemplate: RuleTemplate;

  cgmBloodGlucoseLevelRuleTemplate: RuleTemplate;

  cgmMultipleEventsRuleTemplate: RuleTemplate;

  rpmVitalAlertRuleTemplate: RuleTemplate;

  rpmDeviceInactivityAlertRuleTemplate: RuleTemplate;

  rpmDeviceLowBatteryAlertRuleTemplate: RuleTemplate;

  isQuickPatientAssignment: boolean;

  constructor(data: RawMonitoringProtocol) {
    this.id = data._id;
    this.name = data.name;
    this.notifyInterval = data.notifyInterval;
    this.eventRules = data.eventRules;
    this.heartRateRules = data.heartRateRules;
    this.ecgInactivityAlertRules = data.ecgInactivityAlertRules;
    this.spo2InactivityAlertRules = data.spo2InactivityAlertRules;
    this.temperatureInactivityAlertRules = data.temperatureInactivityAlertRules;
    this.bpInactivityAlertRules = data.bpInactivityAlertRules;
    this.multipleEventsRules = data.multipleEventsRules || undefined;
    this.bpRules = data.bpRules;
    this.temperatureRules = data.temperatureRules;
    this.spo2Rules = data.spo2Rules;
    this.cgmTimeOutOfRangeRules = data.cgmTimeOutOfRangeRules;
    this.cgmBloodGlucoseLevelRules = data.cgmBloodGlucoseLevelRules;
    this.cgmMultipleEventsRules = data.cgmMultipleEventsRules;
    this.rpmVitalAlertRules = data.rpmVitalAlertRules;
    this.rpmDeviceInactivityAlertRules = data.rpmDeviceInactivityAlertRules;
    this.rpmDeviceLowBatteryAlertRules = data.rpmDeviceLowBatteryAlertRules;
    this.customStatus = data.customStatus;
    this.parentId = data.parentId;
    this.patientId = data.patientId;
    this.customStatusDisabled = data.customStatusDisabled;
    this.eventRuleTemplate = data.eventRuleTemplate;
    this.heartRateRuleTemplate = data.heartRateRuleTemplate;
    this.multipleEventsRuleTemplate = data.multipleEventsRuleTemplate;
    this.ecgInactivityAlertRuleTemplate = data.ecgInactivityAlertRuleTemplate;
    this.spo2InactivityAlertRuleTemplate = data.spo2InactivityAlertRuleTemplate;
    this.temperatureInactivityAlertRuleTemplate =
      data.temperatureInactivityAlertRuleTemplate;
    this.bpInactivityAlertRuleTemplate = data.bpInactivityAlertRuleTemplate;
    this.bpRuleTemplate = data.bpRuleTemplate;
    this.temperatureRuleTemplate = data.temperatureRuleTemplate;
    this.spo2RuleTemplate = data.spo2RuleTemplate;
    this.cgmTimeOutOfRangeRuleTemplate = data.cgmTimeOutOfRangeRuleTemplate;
    this.cgmBloodGlucoseLevelRuleTemplate =
      data.cgmBloodGlucoseLevelRuleTemplate;
    this.cgmMultipleEventsRuleTemplate = data.cgmMultipleEventsRuleTemplate;
    this.rpmVitalAlertRuleTemplate = data.rpmVitalAlertRuleTemplate;
    this.rpmDeviceInactivityAlertRuleTemplate =
      data.rpmDeviceInactivityAlertRuleTemplate;
    this.rpmDeviceLowBatteryAlertRuleTemplate =
      data.rpmDeviceLowBatteryAlertRuleTemplate;
    this.isQuickPatientAssignment = data.isQuickPatientAssignment;
  }
}
