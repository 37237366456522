/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-use-before-define */
import Axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { notification, message as messageApi } from 'antd';

import { axiosDefaultConfig, config } from 'core/config';
import { hideLoader, showLoader } from 'reducers/loader';
import { logoutUser } from 'pages/signIn';
import { store } from 'store';
import { RolesEnum, UserModel } from 'core/models';
import { ArgsProps } from 'antd/es/notification/interface';
import dayjs from 'dayjs';
import getCurrentUser from 'routes/routes.service';
import { RawUser } from 'core/models/userModel';
import { setUser } from 'reducers/session';
import { getStoredAccessToken, storeAccessToken } from './localStorage';

const {
  signIn,
  pmManagement,
  patientManagement,
  dashboard,
  dashboardEvent,
  dashboardRpmAlert,
  studies,
  userManagement,
  patients,
  orderManagement,
} = config.paths;

const StatusInfo = 1001;
const StatusSuccess = 1002;
const StatusWarning = 1003;
const StatusError = 1004;

export interface ResultData<T = unknown> {
  data: T;
  code: number;
  message?: string;
}

export interface ResultDatas<T = unknown[]> {
  data: T[];
  code: number;
  message?: string;
}

export const axiosApi = (): AxiosInstance =>
  Axios.create({
    baseURL: config.paths.api,
    headers: {
      ...axiosDefaultConfig().headers,
    },
    timeout: 1000 * 60 * 60, // 设置超时时间，单位为毫秒
  });

export const baseUrlUser = (roleName?: string): string => {
  switch (roleName) {
    case RolesEnum.VivaLNKAdmin:
      return `${userManagement}${pmManagement}`;
    case RolesEnum.ProgramManager:
      return `${userManagement}${pmManagement}`;
    case RolesEnum.Admin:
      return patientManagement;
    case RolesEnum.Technician:
    case RolesEnum.Physician:
      return dashboardEvent;
    case RolesEnum.Researcher:
      return patients;
    case RolesEnum.ClinicalTrialCoordinator:
      return orderManagement;
    default:
      return signIn;
  }
};

export const warnNotificationWith = ({
  message,
  description,
}: ArgsProps): void => {
  notification.warning({
    message,
    description,
  });
};

export const errorNotificationWith = ({
  message,
  description,
}: ArgsProps): void => {
  notification.error({
    message,
    description,
  });
};

export const successNotificationWith = ({
  message,
  description,
}: ArgsProps): void => {
  notification.success({
    message,
    description,
  });
};

export const infoMessage = (message: string): void => {
  messageApi.info(message);
};

export const errorMessage = (message: string): void => {
  messageApi.error(message);
};

export const successMessage = (message: string): void => {
  messageApi.success(message);
};

export const warningMessage = (message: string): void => {
  messageApi.warning(message);
};

export const statusCodesCheck = (response?: AxiosResponse): boolean => {
  return !!response && response.status < 300 && response.status >= 200;
};

function handleAxiosErrors<T>(
  e: AxiosError,
  error?: (er: AxiosError<T>) => AxiosResponse<T> | void,
): AxiosResponse<T> | void {
  if (!e || !e.response || e.response.status >= 500) {
    errorNotificationWith({
      message: e.response?.data?.message || e.message,
    });
    return undefined;
  }
  if (error) {
    return error(e);
  }
  if (e?.response?.status === 401) {
    errorNotificationWith({ message: e?.response?.data?.message });
    setTimeout(logoutUser, 1000);
    return undefined;
  }
  if (
    e?.response?.status === 403 &&
    window.location.pathname !== config.paths.forbidden
  ) {
    let message = e?.response?.data?.message || e.message;
    message = message?.replace('.', '');
    message = `${message} url:${e?.config?.url} `;
    // window.location.replace(config.paths.forbidden);
    errorNotificationWith({ message });
    return undefined;
  }
  if (
    e?.response?.status === 404 &&
    window.location.pathname !== config.paths.notFound
  ) {
    let message = e?.response?.data?.message || e.message;
    message = message?.replace('.', '');
    message = `${message} url:${e?.config?.url} `;
    // window.location.replace(config.paths.notFound);
    errorNotificationWith({ message });
    return undefined;
  }
  if (e?.response?.status === 409) {
    errorNotificationWith({
      message: e?.response?.data?.message || e.message,
    });
    return undefined;
  }
  if (!Axios.isCancel(e)) {
    const code = e?.response?.data?.code;
    const message =
      e?.response?.data?.message ||
      Object.values(e?.response?.data?.errors)?.[0] ||
      e.message;
    if (code === StatusWarning) {
      warningMessage(message);
      return undefined;
    }
    if (code === StatusError) {
      errorMessage(message);
      return undefined;
    }
    errorNotificationWith({
      message:
        e?.response?.data?.message ||
        Object.values(e?.response?.data?.errors)?.[0] ||
        e.message,
    });
    return undefined;
  }
  return undefined;
}

interface ApiWrapper<T = unknown> {
  success: () => Promise<AxiosResponse<T> | void>;
  error?: (e: AxiosError<T>) => AxiosResponse<T> | void;
  isLoading?: boolean;
}

const apiWrapperInternal = async <T = unknown>({
  success,
  error,
  isLoading,
}: ApiWrapper<T>): Promise<AxiosResponse<T> | void> => {
  let isShowLoading = true;
  if (isLoading != null && !isLoading) {
    isShowLoading = false;
  }
  try {
    if (isShowLoading) {
      store.dispatch(showLoader());
    }

    return await success();
  } catch (e: any) {
    if (e.isAxiosError) {
      return handleAxiosErrors(e, error);
    }
    errorNotificationWith({
      message: e?.response?.data?.message || e.message,
    });
    throw e.response;
  } finally {
    if (isShowLoading) {
      store.dispatch(hideLoader());
    }
  }
};

// let timeoutId: NodeJS.Timeout;
export const apiWrapper = async <T = unknown>({
  success,
  error,
  isLoading,
}: ApiWrapper<T>): Promise<AxiosResponse<T> | void> => {
  // if (timeoutId) {
  //   clearTimeout(timeoutId);
  // }
  // timeoutId = setTimeout(() => logoutUser(), LOGOUT_TIMEOUT);

  refreshUserData();

  replaceJWTToken();

  return apiWrapperInternal<T>({
    success,
    error,
    isLoading,
  });
};

let tokenTmp: string | null = null;
const refreshUserData = async () => {
  const token = getStoredAccessToken();
  if (tokenTmp == null || tokenTmp === '') {
    tokenTmp = token;
  }

  const accessJWT = token?.split('.')?.[1] || '';
  if (tokenTmp !== token && accessJWT !== '') {
    const { sub } = JSON.parse(atob(accessJWT));
    if (sub) {
      const response = await axiosApi().get<RawUser>(`/currentUserInfo/${sub}`);
      if (response) {
        store.dispatch(
          setUser({
            user: new UserModel(response.data),
          }),
        );
      }
    }
  }
  tokenTmp = token;
};

export const saveAsFile = (data: Blob, name: string): void => {
  const anchorElement: HTMLAnchorElement = document.createElement('a');
  const url = window.URL.createObjectURL(data);
  anchorElement.style.display = 'none';
  anchorElement.download = name;
  anchorElement.href = url;
  document.body.appendChild(anchorElement);
  anchorElement.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(anchorElement);
};

export type VoidEmptyFunction = () => void;

export const replaceJWTToken = async (): Promise<void> => {
  try {
    const token = getStoredAccessToken();
    if (token === '' || !token) {
      return;
    }
    const accessJWT = token.split('.')?.[1] || '';
    const { exp, iat } = JSON.parse(atob(accessJWT));
    const sumDiff = (exp ?? 0) - (iat ?? 0);
    const nowDiff = (exp ?? 0) - dayjs().unix();
    if (nowDiff <= 0 || nowDiff >= sumDiff / 2) {
      return;
    }

    const response = await axiosApi().post<any>('/token/update', {});
    const accessToken = response?.data?.data?.token;
    if (accessToken != null) {
      storeAccessToken(accessToken);
    }
  } catch (e: any) {
    console.log('token update error');
  }
};
