import { RolesEnum } from 'core/models';
import { RoleName } from 'core/models/roleModel';
import { config } from 'core/config';

export type Rules = {
  [key in RoleName]: string[];
};

const {
  baseUrl,
  profile,
  forbidden,
  clinics,
  changePassword,
  restorePassword,
  verifyPassword,
  addPath,
  pmManagement,
  adminManagement,
  techManagement,
  physicianManagement,
  devices,
  patientManagement,
  monitoringProtocols,
  dashboard,
  dashboardEvent,
  dashboardRpmAlert,
  ecgDataChunks,
  pec,
  reports,
  generateReport,
  reportsPdf,
  settings,
  analysisSetting,
  otherSetting,
  groupManagement,
  historyView,
  ecgRecord,
  patients,
  sites,
  studies,
  studiesManagement,
  researchersManagement,
  clinicalTrialCoordinatorManagement,
  userManagement,
  studiesPatients,
  superAdminManagement,
  templateManagement,
  emailTemplate,
  smsTemplate,
  dataAnalytics,
  tenant,
  tenantDevice,

  rpm,
  rpmEcg,
  rpmRT,
  addRpmPatient,

  orderManagement,
} = config.paths;

export const rules = (): Rules => {
  const defaultsRules = [
    baseUrl,
    profile,
    forbidden,
    profile,
    `${profile}${changePassword}`,
    restorePassword,
    `${restorePassword}/:token`,
    `${verifyPassword}/:token`,
    `${dataAnalytics}`,
    pec,
  ];

  return {
    VivaLNKAdmin: [
      ...defaultsRules,
      userManagement,
      `${userManagement}${pmManagement}`,
      `${userManagement}${pmManagement}/:id`,
      `${userManagement}${pmManagement}${addPath}`,
      `${userManagement}${researchersManagement}`,
      `${userManagement}${researchersManagement}/:id`,
      `${userManagement}${researchersManagement}${addPath}`,
      `${userManagement}${adminManagement}`,
      `${userManagement}${adminManagement}/:id`,
      `${userManagement}${adminManagement}${addPath}`,
      `${userManagement}${techManagement}`,
      `${userManagement}${techManagement}/:id`,
      `${userManagement}${techManagement}${addPath}`,
      `${userManagement}${physicianManagement}`,
      `${userManagement}${physicianManagement}/:id`,
      `${userManagement}${physicianManagement}${addPath}`,
      `${userManagement}${clinicalTrialCoordinatorManagement}`,
      `${userManagement}${clinicalTrialCoordinatorManagement}/:id`,
      `${userManagement}${clinicalTrialCoordinatorManagement}${addPath}`,
      clinics,
      `${clinics}/:id`,
      `${clinics}${addPath}`,
      ecgDataChunks,
      `${tenant}${tenantDevice}`,
    ],
    ProgramManager: [
      ...defaultsRules,
      userManagement,
      `${userManagement}${pmManagement}`,
      `${userManagement}${pmManagement}/:id`,
      `${userManagement}${pmManagement}${addPath}`,
      `${userManagement}${researchersManagement}`,
      `${userManagement}${researchersManagement}/:id`,
      `${userManagement}${researchersManagement}${addPath}`,
      `${userManagement}${adminManagement}`,
      `${userManagement}${adminManagement}/:id`,
      `${userManagement}${adminManagement}${addPath}`,
      `${userManagement}${techManagement}`,
      `${userManagement}${techManagement}/:id`,
      `${userManagement}${techManagement}${addPath}`,
      `${userManagement}${physicianManagement}`,
      `${userManagement}${physicianManagement}/:id`,
      `${userManagement}${physicianManagement}${addPath}`,
      `${userManagement}${clinicalTrialCoordinatorManagement}`,
      `${userManagement}${clinicalTrialCoordinatorManagement}/:id`,
      `${userManagement}${clinicalTrialCoordinatorManagement}${addPath}`,
      // pmManagement,
      // `${pmManagement}/:id`,
      // `${pmManagement}${addPath}`,
      // adminManagement,
      // `${adminManagement}/:id`,
      // `${adminManagement}${addPath}`,
      // techManagement,
      // `${techManagement}/:id`,
      // `${techManagement}${addPath}`,
      // physicianManagement,
      // `${physicianManagement}/:id`,
      // `${physicianManagement}${addPath}`,
      // researchersManagement,
      // `${researchersManagement}/:id`,
      // `${researchersManagement}${addPath}`,
      monitoringProtocols,
      `${monitoringProtocols}/:id`,
      `${monitoringProtocols}${addPath}`,
      `${templateManagement}`,
      `${templateManagement}${emailTemplate}`,
      `${templateManagement}${emailTemplate}${addPath}`,
      `${templateManagement}${emailTemplate}/:id`,
      `${templateManagement}${smsTemplate}`,
      `${templateManagement}${smsTemplate}${addPath}`,
      `${templateManagement}${smsTemplate}/:id`,
      `${settings}`,
      `${settings}${analysisSetting}`,
      `${settings}${otherSetting}`,
      `${settings}${groupManagement}`,
      historyView,
      sites,
      // studies,
      // `${studies}/:id`,
      `${studies}/:id/:studyId`,
      `${studies}/:id${patients}/:studyId`,
      studiesManagement,
      `${studiesManagement}/:id`,
      rpmRT,
    ],
    Admin: [
      ...defaultsRules,
      devices,
      `${devices}/:id`,
      `${devices}${addPath}`,
      patientManagement,
      `${patientManagement}/:id`,
      `${patientManagement}/:id${devices}`,
      `${patientManagement}/:id${devices}${addPath}`,
      `${patientManagement}/:id${devices}/:deviceId`,
      `${patientManagement}${addPath}`,
      rpmRT,
    ],
    Technician: [
      ...defaultsRules,
      dashboard,
      dashboardEvent,
      dashboardRpmAlert,
      patients,
      `${patients}/:id${dashboard}`,
      `${patients}/:id${dashboard}/:studyId`,
      `${patients}/:id${dashboard}/:studyId/:eventId/:tabId`,
      `${patients}${ecgRecord}`,
      reports,
      generateReport,
      reportsPdf,
      studies,
      // `${studies}/:id/`,
      `${studies}/:id${patients}/:studyId`,
      `${studies}-sites/:id`,

      // rpm,
      // rpmEcg,
      rpmRT,
      addRpmPatient,
    ],
    Physician: [
      ...defaultsRules,
      dashboard,
      dashboardEvent,
      dashboardRpmAlert,
      patients,
      `${patients}/:id${dashboard}`,
      `${patients}/:id${dashboard}/:studyId`,
      `${patients}/:id${dashboard}/:studyId/:eventId/:tabId`,
      `${patients}${ecgRecord}`,
      reports,
      generateReport,
      reportsPdf,
      studies,
      // `${studies}/:id/`,
      `${studies}/:id${patients}/:studyId`,
      `${studies}-sites/:id`,
      rpmRT,
    ],
    Researcher: [
      ...defaultsRules,
      studies,
      // `${studies}/:id/`,
      `${studies}/:id${patients}/:studyId`,
      `${studies}-sites/:id`,
      patients,
      `${patients}/:id${dashboard}`,
      `${patients}/:id${dashboard}/:studyId`,
      `${patients}/:id${dashboard}/:studyId/:eventId/:tabId`,
      `${patients}${ecgRecord}`,
      reports,
      generateReport,
      reportsPdf,
      rpmRT,
    ],
    ClinicalTrialCoordinator: [...defaultsRules, orderManagement],
  };
};

export const userPathByRole = (userRole?: RolesEnum): string => {
  switch (userRole) {
    case RolesEnum.ProgramManager:
      return pmManagement;
    case RolesEnum.Admin:
      return adminManagement;
    case RolesEnum.Technician:
      return techManagement;
    case RolesEnum.Physician:
      return physicianManagement;
    case RolesEnum.Researcher:
      return researchersManagement;
    case RolesEnum.ClinicalTrialCoordinator:
      return clinicalTrialCoordinatorManagement;
    default:
      return '';
  }
};
