// eslint-disable-next-line no-use-before-define
import React, {
  lazy,
  ReactElement,
  Suspense,
  useCallback,
  useEffect,
} from 'react';
import Axios from 'axios';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createZitadelAuth, ZitadelConfig } from '@zitadel/react';

import { config, apiBaseUrl } from 'core/config';
import { ApplicationState } from 'store/combineReducers';
import { Layout } from 'layout';
import { PrivateRoute } from 'shared/components/privateRouter';
import { Loader } from 'shared/components';
import { RolesEnum } from 'core/models';
import { getStoredSsoConfig } from 'utils/localStorage';
import { getCurrentUser } from './routes.service';

const Home = lazy(() => import('pages/home'));

const SignIn = lazy(() => import('pages/signIn'));

const SsoLanding = lazy(() => import('pages/sso/landing'));

const SsoCallback = lazy(() => import('pages/sso/callback'));

const NotFound = lazy(() => import('pages/notFound'));

const Profile = lazy(() => import('pages/profile'));

const RestorePassword = lazy(() => import('pages/restorePassword'));

const ResetPassword = lazy(() => import('pages/resetPassword'));

const ClinicsManagement = lazy(() => import('pages/clinicsManagement'));

const ClinicAdd = lazy(() => import('pages/clinicsManagement/pages/clinicAdd'));

const ClinicEdit = lazy(
  () => import('pages/clinicsManagement/pages/clinicEdit'),
);

const UsersManagement = lazy(() => import('pages/usersManagement'));

const UserAdd = lazy(() => import('pages/usersManagement/pages/userAdd'));

const UserEdit = lazy(() => import('pages/usersManagement/pages/userEdit'));

const DevicesManagement = lazy(() => import('pages/devicesManagement'));

const PatientManagement = lazy(() => import('pages/patientsManagement'));

const PatientsAdd = lazy(
  () => import('pages/patientsManagement/pages/patientAdd'),
);

const PatientsEdit = lazy(
  () => import('pages/patientsManagement/pages/patientEdit'),
);

const PatientsTabs = lazy(
  () => import('pages/patientsManagement/components/patientTabs'),
);

const PatientDevices = lazy(
  () => import('pages/patientsManagement/pages/patientDevices'),
);

const PatientDeviceAdd = lazy(
  () => import('pages/patientsManagement/pages/patientDeviceAdd'),
);

const PatientDashboard = lazy(
  () => import('pages/patientsManagement/pages/patientDashboard'),
);

const DisclosureView = lazy(
  () => import('pages/patientsManagement/pages/disclosureView'),
);

const MonitoringProtocolsManagement = lazy(
  () => import('pages/monitoringProtocolsManagement'),
);

const MonitoringProtocolsAdd = lazy(
  () =>
    import('pages/monitoringProtocolsManagement/pages/monitoringProtocolAdd'),
);

const MonitoringProtocolsEdit = lazy(
  () =>
    import('pages/monitoringProtocolsManagement/pages/monitoringProtocolEdit'),
);

const MonitoringProtocolsSelfDefined = lazy(
  () =>
    import(
      'pages/monitoringProtocolsManagement/pages/monitoringProtocolSelfDefined'
    ),
);

const DashboardManagementEvent = lazy(
  () => import('pages/dashboardManagement/event'),
);

const DashboardManagementRpmAlert = lazy(
  () => import('pages/dashboardManagement/rpmAlert'),
);

const EcgDataChunks = lazy(() => import('pages/ecgDataChunks'));

const DVParts = lazy(() => import('pages/patientsManagement/pages/dvParts'));

const ReportsManagement = lazy(() => import('pages/reports'));

const GenerateReport = lazy(() => import('pages/generateReport'));

const ReportsPdf = lazy(() => import('pages/reportsPdf'));

const AnalysisSetting = lazy(() => import('pages/settings/analysisSetting'));

const OtherSetting = lazy(() => import('pages/settings/otherSetting'));

const GroupManagement = lazy(() => import('pages/settings/groupManagement'));

const History = lazy(() => import('pages/history'));

const ECGRecordDashboard = lazy(
  () => import('pages/patientsManagement/pages/ecgRecordDashboard'),
);

const Sites = lazy(() => import('pages/sites'));

const Studies = lazy(() => import('pages/studies'));

const StudiesView = lazy(() => import('pages/studies/studiesView'));

const StudiesViewPatients = lazy(
  () => import('pages/studies/studiesViewPatients'),
);

const StudiesViewSites = lazy(() => import('pages/studies/studiesViewSites'));

const StudiesManagement = lazy(() => import('pages/studiesManagement'));
const StudiesManagementView = lazy(
  () => import('pages/studiesManagement/studiesView'),
);

const UserTab = lazy(() => import('pages/userManagement'));

const TenantDevice = lazy(() => import('pages/tenant/device'));
const TenantDeviceDetails = lazy(
  () => import('pages/tenant/device/page/details'),
);

const Download = lazy(() => import('pages/download'));

const EmailTemplate = lazy(() => import('pages/templateManagement/email'));
const EmailTemplateAdd = lazy(
  () => import('pages/templateManagement/email/page/add'),
);
const EmailTemplateEdit = lazy(
  () => import('pages/templateManagement/email/page/edit'),
);

const SmsTemplate = lazy(() => import('pages/templateManagement/sms'));
const SmsTemplateAdd = lazy(
  () => import('pages/templateManagement/sms/page/add'),
);
const SmsTemplateEdit = lazy(
  () => import('pages/templateManagement/sms/page/edit'),
);

const Rpm = lazy(() => import('pages/rpm/index'));
const RpmEcg = lazy(() => import('pages/rpm/ecg'));
const RpmRT = lazy(() => import('pages/rpm/dashboard'));
const RpmRTList = lazy(() => import('pages/rpm/dashboard/ListView'));
const AddRpmPatient = lazy(() => import('pages/rpm/addPatient'));

const DataAnalyticsDashboard = lazy(
  () => import('pages/dataAnalytics/dashboard'),
);
const AssignedUsers = lazy(() => import('pages/dataAnalytics/assignedUsers'));
const DataAnalyticsDashboardDetails = lazy(
  () => import('pages/dataAnalytics/chart'),
);

const OrderManagement = lazy(() => import('pages/orderManage/index'));

const {
  baseUrl,
  signIn,
  download,
  forbidden,
  clinics,
  profile,
  changePassword,
  restorePassword,
  verifyPassword,
  addPath,
  pmManagement,
  adminManagement,
  techManagement,
  physicianManagement,
  devices,
  patientManagement,
  monitoringProtocols,
  dashboard,
  dashboardEvent,
  dashboardRpmAlert,
  ecgDataChunks,
  pec,
  reports,
  generateReport,
  reportsPdf,
  settings,
  analysisSetting,
  otherSetting,
  groupManagement,
  historyView,
  ecgRecord,
  patients,
  sites,
  studies,
  studiesManagement,
  researchersManagement,
  clinicalTrialCoordinatorManagement,
  userManagement,
  superAdminManagement,
  templateManagement,
  emailTemplate,
  smsTemplate,
  dataAnalytics,
  assignedUsers,
  dataAnalyticsDetails,

  rpm,
  rpmEcg,
  rpmRT,
  rpmRTList,
  addRpmPatient,

  orderManagement,

  tenant,
  tenantDevice,
} = config.paths;

export default function Routes(): ReactElement {
  const { isAuth, user } = useSelector(
    (state: ApplicationState) => state.session,
  );

  const getCurrentUserCallback = useCallback(async () => {
    try {
      await getCurrentUser();
    } catch (e) {
      // console.error('get current user callback error: ', e);
    }
  }, []);

  useEffect(() => {
    if (!user) {
      const ssoConfig = getStoredSsoConfig();
      if (ssoConfig.authority) {
        const sso = createZitadelAuth(ssoConfig);
        sso.userManager.getUser().then((authUser) => {
          if (authUser) {
            getCurrentUserCallback();
          }
        });
      }
    }
  }, [user, getCurrentUserCallback]);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    if (isAuth && !user) {
      getCurrentUserCallback();
    }
    return (): void => {
      source.cancel();
    };
  }, [isAuth, user, getCurrentUserCallback]);

  return (
    <Loader>
      <BrowserRouter>
        <Suspense fallback={<Loader isLoading />}>
          <Switch>
            <Route path={`${reportsPdf}/:id`} exact>
              <Helmet>
                <title>Reports Pdf</title>
                <meta name="description" content="Reports Pdf" />
              </Helmet>
              <ReportsPdf />
            </Route>
            <Layout>
              <Switch>
                <Route path="/callback" exact>
                  <SsoCallback />
                </Route>
                <Route path={signIn} exact>
                  <SsoLanding />
                </Route>
                <PrivateRoute
                  path={`${reportsPdf}/edit/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="Reports Pdf"
                  metaContent="Reports Pdf"
                >
                  <ReportsPdf />
                </PrivateRoute>
                <PrivateRoute
                  path={`${orderManagement}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Order Management"
                  metaContent="Order Management"
                >
                  <OrderManagement />
                </PrivateRoute>
                <PrivateRoute
                  path={`${rpm}`}
                  isAuthenticated={isAuth}
                  exact
                  title="RPM"
                  metaContent="RPM"
                >
                  <Rpm />
                </PrivateRoute>
                <PrivateRoute
                  path={`${rpmEcg}`}
                  isAuthenticated={isAuth}
                  exact
                  title="ECG RPM"
                  metaContent="ECG RPM"
                >
                  <RpmEcg />
                </PrivateRoute>
                <PrivateRoute
                  path={`${rpmRT}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Dashboard RPM"
                  metaContent="Dashboard RPM"
                >
                  <RpmRT />
                </PrivateRoute>
                <PrivateRoute
                  path={`${rpmRTList}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Dashboard RPM"
                  metaContent="Dashboard RPM"
                >
                  <RpmRTList />
                </PrivateRoute>
                <PrivateRoute
                  path={`${addRpmPatient}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Add RPM Patient"
                  metaContent="Add RPM Patient"
                >
                  <AddRpmPatient />
                </PrivateRoute>
                <PrivateRoute
                  path={`${dataAnalytics}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Data Analytics"
                  metaContent="Data Analytics"
                >
                  <DataAnalyticsDashboard />
                </PrivateRoute>
                <PrivateRoute
                  path={`${dataAnalytics}/:id${dataAnalyticsDetails}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Data Analytics Details"
                  metaContent="Data Analytics Details"
                >
                  <DataAnalyticsDashboardDetails />
                </PrivateRoute>
                <PrivateRoute
                  path={`${dataAnalytics}/:id${assignedUsers}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Assigned Users"
                  metaContent="Assigned Users"
                >
                  <AssignedUsers />
                </PrivateRoute>
                <PrivateRoute
                  path={`${templateManagement}${emailTemplate}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Email Template"
                  metaContent="Email Template"
                >
                  <EmailTemplate />
                </PrivateRoute>
                <PrivateRoute
                  path={`${templateManagement}${emailTemplate}${addPath}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Email Template Add"
                  metaContent="Email Template Add"
                >
                  <EmailTemplateAdd />
                </PrivateRoute>
                <PrivateRoute
                  path={`${templateManagement}${emailTemplate}/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="Email Template Edit"
                  metaContent="Email Template Edit"
                >
                  <EmailTemplateEdit />
                </PrivateRoute>
                <PrivateRoute
                  path={`${templateManagement}${smsTemplate}`}
                  isAuthenticated={isAuth}
                  exact
                  title="SMS Template"
                  metaContent="SMS Template"
                >
                  <SmsTemplate />
                </PrivateRoute>
                <PrivateRoute
                  path={`${templateManagement}${smsTemplate}${addPath}`}
                  isAuthenticated={isAuth}
                  exact
                  title="SMS Template Add"
                  metaContent="SMS Template Add"
                >
                  <SmsTemplateAdd />
                </PrivateRoute>
                <PrivateRoute
                  path={`${templateManagement}${smsTemplate}/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="SMS Template Edit"
                  metaContent="SMS Template Edit"
                >
                  <SmsTemplateEdit />
                </PrivateRoute>
                <PrivateRoute
                  path={`${tenant}${tenantDevice}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Device Management"
                  metaContent="Device Management"
                >
                  <TenantDevice />
                </PrivateRoute>
                <PrivateRoute
                  path={`${tenant}${tenantDevice}/:id/:type/:tenantName`}
                  isAuthenticated={isAuth}
                  exact
                  title="Device Management"
                  metaContent="Device Management"
                >
                  <TenantDeviceDetails />
                </PrivateRoute>
                <PrivateRoute
                  path={`${studies}/:id${patients}`}
                  isAuthenticated={isAuth}
                  exact
                  title="studies patients"
                  metaContent="studies patients"
                >
                  <PatientManagement />
                </PrivateRoute>
                <PrivateRoute
                  path={`${userManagement}${pmManagement}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Program Manager Management"
                  metaContent="Program Manager Management"
                >
                  <UserTab>
                    <UsersManagement userRole={RolesEnum.ProgramManager} />
                  </UserTab>
                </PrivateRoute>
                <PrivateRoute
                  path={`${userManagement}${pmManagement}${addPath}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Program Manager Add"
                  metaContent="Program Manager Add"
                >
                  <UserTab>
                    <UserAdd userRole={RolesEnum.ProgramManager} />
                  </UserTab>
                </PrivateRoute>
                <PrivateRoute
                  path={`${userManagement}${pmManagement}/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="Program Manager Edit"
                  metaContent="Program Manager Edit"
                >
                  <UserTab>
                    <UserEdit userRole={RolesEnum.ProgramManager} />
                  </UserTab>
                </PrivateRoute>
                <PrivateRoute
                  path={`${userManagement}${researchersManagement}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Researcher Management"
                  metaContent="Researcher Management"
                >
                  <UserTab>
                    <UsersManagement userRole={RolesEnum.Researcher} />
                  </UserTab>
                </PrivateRoute>
                <PrivateRoute
                  path={`${userManagement}${researchersManagement}${addPath}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Researcher Add"
                  metaContent="Researcher Add"
                >
                  <UserTab>
                    <UserAdd userRole={RolesEnum.Researcher} />
                  </UserTab>
                </PrivateRoute>
                <PrivateRoute
                  path={`${userManagement}${researchersManagement}/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="Researcher Edit"
                  metaContent="Researcher Edit"
                >
                  <UserTab>
                    <UserEdit userRole={RolesEnum.Researcher} />
                  </UserTab>
                </PrivateRoute>
                <PrivateRoute
                  path={`${userManagement}${adminManagement}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Admin Management"
                  metaContent="Admin Management"
                >
                  <UserTab>
                    <UsersManagement userRole={RolesEnum.Admin} />
                  </UserTab>
                </PrivateRoute>
                <PrivateRoute
                  path={`${userManagement}${adminManagement}${addPath}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Admin Add"
                  metaContent="Admin Add"
                >
                  <UserTab>
                    <UserAdd userRole={RolesEnum.Admin} />
                  </UserTab>
                </PrivateRoute>
                <PrivateRoute
                  path={`${userManagement}${adminManagement}/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="Admin Edit"
                  metaContent="Admin Edit"
                >
                  <UserTab>
                    <UserEdit userRole={RolesEnum.Admin} />
                  </UserTab>
                </PrivateRoute>
                <PrivateRoute
                  path={`${userManagement}${techManagement}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Technician Management"
                  metaContent="Technician Management"
                >
                  <UserTab>
                    <UsersManagement userRole={RolesEnum.Technician} />
                  </UserTab>
                </PrivateRoute>
                <PrivateRoute
                  path={`${userManagement}${techManagement}${addPath}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Technician Add"
                  metaContent="Technician Add"
                >
                  <UserTab>
                    <UserAdd userRole={RolesEnum.Technician} />
                  </UserTab>
                </PrivateRoute>
                <PrivateRoute
                  path={`${userManagement}${techManagement}/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="Technician Edit"
                  metaContent="Technician Edit"
                >
                  <UserTab>
                    <UserEdit userRole={RolesEnum.Technician} />
                  </UserTab>
                </PrivateRoute>
                <PrivateRoute
                  path={`${userManagement}${physicianManagement}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Physician Management"
                  metaContent="Physician Management"
                >
                  <UserTab>
                    <UsersManagement userRole={RolesEnum.Physician} />
                  </UserTab>
                </PrivateRoute>
                <PrivateRoute
                  path={`${userManagement}${physicianManagement}${addPath}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Physician Add"
                  metaContent="Physician Add"
                >
                  <UserTab>
                    <UserAdd userRole={RolesEnum.Physician} />
                  </UserTab>
                </PrivateRoute>
                <PrivateRoute
                  path={`${userManagement}${physicianManagement}/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="Physician Edit"
                  metaContent="Physician Edit"
                >
                  <UserTab>
                    <UserEdit userRole={RolesEnum.Physician} />
                  </UserTab>
                </PrivateRoute>
                <PrivateRoute
                  path={`${userManagement}${clinicalTrialCoordinatorManagement}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Clinical Trial Coordinator Management"
                  metaContent="Clinical Trial Coordinator Management"
                >
                  <UserTab>
                    <UsersManagement
                      userRole={RolesEnum.ClinicalTrialCoordinator}
                    />
                  </UserTab>
                </PrivateRoute>
                <PrivateRoute
                  path={`${userManagement}${clinicalTrialCoordinatorManagement}${addPath}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Clinical Trial Coordinator Add"
                  metaContent="Clinical Trial Coordinator Add"
                >
                  <UserTab>
                    <UserAdd userRole={RolesEnum.ClinicalTrialCoordinator} />
                  </UserTab>
                </PrivateRoute>
                <PrivateRoute
                  path={`${userManagement}${clinicalTrialCoordinatorManagement}/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="Clinical Trial Coordinator Edit"
                  metaContent="Clinical Trial Coordinator Edit"
                >
                  <UserTab>
                    <UserEdit userRole={RolesEnum.ClinicalTrialCoordinator} />
                  </UserTab>
                </PrivateRoute>
                <PrivateRoute
                  path={`${studies}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Studies"
                  metaContent="Studies"
                >
                  <Studies />
                </PrivateRoute>
                {/* <PrivateRoute
                  path={`${studies}/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="Patients Dashboard Management"
                  metaContent="Patients Dashboard Management"
                >
                  <StudiesView />
                </PrivateRoute> */}
                <PrivateRoute
                  path={`${studies}/:id/:studyId`}
                  isAuthenticated={isAuth}
                  exact
                  title="Patients Dashboard Management"
                  metaContent="Patients Dashboard Management"
                >
                  <StudiesView />
                </PrivateRoute>
                <PrivateRoute
                  path={`${studies}/:id${patients}/:studyId`}
                  isAuthenticated={isAuth}
                  exact
                  title="Patients Dashboard Management"
                  metaContent="Patients Dashboard Management"
                >
                  <StudiesViewPatients />
                </PrivateRoute>
                <PrivateRoute
                  path={`${studies}-sites/:id/`}
                  isAuthenticated={isAuth}
                  exact
                  title="studies Sites"
                  metaContent="studies Sites"
                >
                  <StudiesViewSites />
                </PrivateRoute>
                <PrivateRoute
                  path={`${studiesManagement}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Studies Management"
                  metaContent="Studies Management"
                >
                  <StudiesManagement />
                </PrivateRoute>
                <PrivateRoute
                  path={`${studiesManagement}/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="Patients Dashboard Management"
                  metaContent="Patients Dashboard Management"
                >
                  <StudiesManagementView />
                </PrivateRoute>
                <PrivateRoute
                  path={`${sites}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Sites"
                  metaContent="Sites"
                >
                  <Sites />
                </PrivateRoute>
                <PrivateRoute
                  path={`${patients}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Patients"
                  metaContent="Patients"
                >
                  <PatientManagement />
                </PrivateRoute>
                <PrivateRoute
                  path={`${patients}/:id${dashboard}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Patients Dashboard Management"
                  metaContent="Patients Dashboard Management"
                >
                  <PatientDashboard />
                </PrivateRoute>
                <PrivateRoute
                  path={[
                    `${patients}/:id${dashboard}/:studyId`,
                    `${patients}/:id${dashboard}/:studyId/:eventId`,
                  ]}
                  isAuthenticated={isAuth}
                  exact
                  title="Disclosure View"
                  metaContent="Disclosure View"
                >
                  <DisclosureView />
                </PrivateRoute>
                <PrivateRoute
                  path={`${patients}${ecgRecord}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Report Management"
                  metaContent="Report Management"
                >
                  <ECGRecordDashboard />
                </PrivateRoute>
                <PrivateRoute
                  path={historyView}
                  isAuthenticated={isAuth}
                  exact
                  title="History"
                  metaContent="History"
                >
                  <History />
                </PrivateRoute>
                <PrivateRoute
                  path={`${settings}${analysisSetting}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Analysis Setting"
                  metaContent="Analysis Setting"
                >
                  <AnalysisSetting />
                </PrivateRoute>
                <PrivateRoute
                  path={`${settings}${otherSetting}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Other Setting"
                  metaContent="Other Setting"
                >
                  <OtherSetting />
                </PrivateRoute>
                <PrivateRoute
                  path={`${settings}${groupManagement}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Group Management"
                  metaContent="Group Management"
                >
                  <GroupManagement />
                </PrivateRoute>
                <PrivateRoute path={baseUrl} isAuthenticated={isAuth} exact>
                  <Home />
                </PrivateRoute>
                <PrivateRoute
                  path={profile}
                  isAuthenticated={isAuth}
                  exact
                  title="Profile"
                  metaContent="Profile"
                >
                  <Profile />
                </PrivateRoute>
                <PrivateRoute
                  path={pmManagement}
                  isAuthenticated={isAuth}
                  exact
                  title="Program Manager Management"
                  metaContent="Program Manager Management"
                >
                  <UsersManagement userRole={RolesEnum.ProgramManager} />
                </PrivateRoute>
                <PrivateRoute
                  path={`${pmManagement}${addPath}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Program Manager Add"
                  metaContent="Program Manager Add"
                >
                  <UserAdd userRole={RolesEnum.ProgramManager} />
                </PrivateRoute>
                <PrivateRoute
                  path={`${pmManagement}/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="Program Manager Edit"
                  metaContent="Program Manager Edit"
                >
                  <UserEdit userRole={RolesEnum.ProgramManager} />
                </PrivateRoute>
                <PrivateRoute
                  path={adminManagement}
                  isAuthenticated={isAuth}
                  exact
                  title="Admin Management"
                  metaContent="Admin Management"
                >
                  <UsersManagement userRole={RolesEnum.Admin} />
                </PrivateRoute>
                <PrivateRoute
                  path={`${adminManagement}${addPath}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Admin Add"
                  metaContent="Admin Add"
                >
                  <UserAdd userRole={RolesEnum.Admin} />
                </PrivateRoute>
                <PrivateRoute
                  path={`${adminManagement}/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="Admin Edit"
                  metaContent="Admin Edit"
                >
                  <UserEdit userRole={RolesEnum.Admin} />
                </PrivateRoute>
                <PrivateRoute
                  path={techManagement}
                  isAuthenticated={isAuth}
                  exact
                  title="Technician Management"
                  metaContent="Technician Management"
                >
                  <UsersManagement userRole={RolesEnum.Technician} />
                </PrivateRoute>
                <PrivateRoute
                  path={`${techManagement}${addPath}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Technician Add"
                  metaContent="Technician Add"
                >
                  <UserAdd userRole={RolesEnum.Technician} />
                </PrivateRoute>
                <PrivateRoute
                  path={`${techManagement}/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="Technician Edit"
                  metaContent="Technician Edit"
                >
                  <UserEdit userRole={RolesEnum.Technician} />
                </PrivateRoute>
                <PrivateRoute
                  path={physicianManagement}
                  isAuthenticated={isAuth}
                  exact
                  title="Physician Management"
                  metaContent="Physician Management"
                >
                  <UsersManagement userRole={RolesEnum.Physician} />
                </PrivateRoute>
                <PrivateRoute
                  path={`${physicianManagement}${addPath}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Physician Add"
                  metaContent="Physician Add"
                >
                  <UserAdd userRole={RolesEnum.Physician} />
                </PrivateRoute>
                <PrivateRoute
                  path={`${physicianManagement}/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="Physician Edit"
                  metaContent="Physician Edit"
                >
                  <UserEdit userRole={RolesEnum.Physician} />
                </PrivateRoute>
                <PrivateRoute
                  path={clinics}
                  isAuthenticated={isAuth}
                  exact
                  title="Clinics Management"
                  metaContent="Clinics Management"
                >
                  <ClinicsManagement />
                </PrivateRoute>
                <PrivateRoute
                  path={`${clinics}${addPath}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Clinics Add"
                  metaContent="Clinics Add"
                >
                  <ClinicAdd />
                </PrivateRoute>
                <PrivateRoute
                  path={`${clinics}/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="Clinics Edit"
                  metaContent="Clinics Edit"
                >
                  <ClinicEdit />
                </PrivateRoute>
                <PrivateRoute
                  path={monitoringProtocols}
                  isAuthenticated={isAuth}
                  exact
                  title="Monitoring Protocols Management"
                  metaContent="Monitoring Protocols Management"
                >
                  <MonitoringProtocolsManagement />
                </PrivateRoute>
                <PrivateRoute
                  path={`${monitoringProtocols}${addPath}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Monitoring Protocols Add"
                  metaContent="Monitoring Protocols Add"
                >
                  <MonitoringProtocolsAdd />
                </PrivateRoute>
                <PrivateRoute
                  path={`${monitoringProtocols}/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="Monitoring Protocols Edit"
                  metaContent="Monitoring Protocols Edit"
                >
                  <MonitoringProtocolsEdit />
                </PrivateRoute>
                <PrivateRoute
                  path={`${monitoringProtocols}/self-defined/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="Monitoring Protocols Self Defined"
                  metaContent="Monitoring Protocols Self Defined"
                >
                  <MonitoringProtocolsSelfDefined />
                </PrivateRoute>
                <PrivateRoute
                  path={devices}
                  isAuthenticated={isAuth}
                  exact
                  title="Devices Management"
                  metaContent="Devices Management"
                >
                  <DevicesManagement />
                </PrivateRoute>
                <PrivateRoute
                  path={patientManagement}
                  isAuthenticated={isAuth}
                  exact
                  title="Patient Management"
                  metaContent="Patient Management"
                >
                  <PatientManagement />
                </PrivateRoute>
                <PrivateRoute
                  path={`${patientManagement}${addPath}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Patient Add"
                  metaContent="Patient Add"
                >
                  <PatientsAdd />
                </PrivateRoute>
                <PrivateRoute
                  path={`${patientManagement}/:id`}
                  isAuthenticated={isAuth}
                  exact
                  title="Patient Edit"
                  metaContent="Patient Edit"
                >
                  <PatientsTabs>
                    <PatientsEdit />
                  </PatientsTabs>
                </PrivateRoute>
                <PrivateRoute
                  path={`${patientManagement}/:id${devices}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Patients Devices Management"
                  metaContent="Patients Devices Management"
                >
                  <PatientsTabs>
                    <PatientDevices />
                  </PatientsTabs>
                </PrivateRoute>
                <PrivateRoute
                  path={`${patientManagement}/:id${devices}/:deviceId`}
                  isAuthenticated={isAuth}
                  exact
                  title="Patients Devices Add"
                  metaContent="Patients Devices Add"
                >
                  <PatientsTabs>
                    <PatientDeviceAdd />
                  </PatientsTabs>
                </PrivateRoute>
                <PrivateRoute
                  path={`${patientManagement}/:id${dashboard}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Patients Dashboard Management"
                  metaContent="Patients Dashboard Management"
                >
                  <PatientDashboard />
                </PrivateRoute>
                <PrivateRoute
                  path={[
                    `${patientManagement}/:id${dashboard}/:studyId`,
                    `${patientManagement}/:id${dashboard}/:studyId/:eventId`,
                  ]}
                  isAuthenticated={isAuth}
                  exact
                  title="Disclosure View"
                  metaContent="Disclosure View"
                >
                  <DisclosureView />
                </PrivateRoute>
                <PrivateRoute
                  path={dashboardEvent}
                  isAuthenticated={isAuth}
                  exact
                  title="Dashboard Management Events"
                  metaContent="Dashboard Management Events"
                >
                  <DashboardManagementEvent />
                </PrivateRoute>
                <PrivateRoute
                  path={dashboardRpmAlert}
                  isAuthenticated={isAuth}
                  exact
                  title="Dashboard Management RPM Alerts"
                  metaContent="Dashboard Management RPM Alerts"
                >
                  <DashboardManagementRpmAlert />
                </PrivateRoute>
                <PrivateRoute
                  path={reports}
                  isAuthenticated={isAuth}
                  exact
                  title="Report Management"
                  metaContent="Report Management"
                >
                  <ReportsManagement />
                </PrivateRoute>
                <PrivateRoute
                  path={`${reports}${generateReport}`}
                  isAuthenticated={isAuth}
                  exact
                  title="Generate Report"
                  metaContent="Generate Report"
                >
                  <GenerateReport />
                </PrivateRoute>
                <PrivateRoute
                  path={ecgDataChunks}
                  isAuthenticated={isAuth}
                  exact
                  title="Ecg Data Chunks"
                  metaContent="Ecg Data Chunks"
                >
                  <EcgDataChunks />
                </PrivateRoute>
                {/* <Route path={`${reportsPdf}/:id`} exact>
                  <Helmet>
                    <title>Reports Pdf</title>
                    <meta name="description" content="Reports Pdf" />
                  </Helmet>
                  <ReportsPdf />
                </Route> */}
                <Route path={`${download}`} exact>
                  <Helmet>
                    <title>Download</title>
                    <meta name="description" content="Download" />
                  </Helmet>
                  <Download />
                </Route>
                <Route path={forbidden} exact>
                  <Helmet>
                    <title>403</title>
                    <meta name="description" content="Not Enough Permissions" />
                  </Helmet>
                  <NotFound code={403} message="Not Enough Permissions" />
                </Route>
                <Route path={restorePassword} exact>
                  <Helmet>
                    <title>Restore Password</title>
                    <meta name="description" content="Restore Password" />
                  </Helmet>
                  <RestorePassword />
                </Route>
                <Route path={`${restorePassword}/:token`} exact>
                  <Helmet>
                    <title>Reset Password</title>
                    <meta name="description" content="Reset Password" />
                  </Helmet>
                  <ResetPassword />
                </Route>
                <Route path={`${verifyPassword}/:token`} exact>
                  <Helmet>
                    <title>Verify Password</title>
                    <meta name="description" content="Verify Password" />
                  </Helmet>
                  <ResetPassword />
                </Route>
                <Route>
                  <Helmet>
                    <title>404</title>
                    <meta name="description" content="Page Not Found" />
                  </Helmet>
                  <NotFound code={404} message="Page Not Found" />
                </Route>
              </Switch>
            </Layout>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </Loader>
  );
}
