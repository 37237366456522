/* eslint-disable no-shadow */
/* eslint-disable no-lonely-if */
/* eslint-disable no-plusplus */

import { getStoredAccessToken } from 'utils/localStorage';
import url from 'url';
import { apiBaseUrl } from 'core/config';
import dayjs from 'dayjs';
import { timeZone } from 'utils/util';

export interface MessageDataModel {
  message: string; // 内容
  type: number; // 消息类型
  alertPatientCount?: number; // 告警病人数量
  alertCount?: number; // 告警数量
  level?: number; // 等级
  isNewAlert?: boolean; // 是否有新告警
  isAudio: boolean; // 是否发出告警声音
  audioQueue?: number[]; // 告警播放队列
}

export enum WsLinkType {
  MESSAGE = 'onmessage',
  ERROR = 'onerror',
  CLOSE = 'onclose',
}

export interface Props {
  updateWsLinkType?: (
    type: WsLinkType.MESSAGE | WsLinkType.ERROR | WsLinkType.CLOSE,
    data?: MessageDataModel,
  ) => void;
}

export function creatWs({ updateWsLinkType }: Props): WebSocket {
  console.log('创建全局websokect', dayjs().format('YYYY-MM-DD HH:mm:ss.SSS'));
  // 创建websokect
  const timeZoneName = timeZone();
  const token = getStoredAccessToken();
  const uri = url.parse(apiBaseUrl);
  const wsProtocol = uri.protocol === 'https:' ? 'wss' : 'ws';
  const wsUrl = `${wsProtocol}://${uri.host ?? window.location.host}${
    uri.path
  }${
    /.*\/$/i.test(uri.path ?? '') ? '' : '/'
  }globalMessage/websocket?token=${token}&timeZone=${timeZoneName}`;

  const ws = new WebSocket(wsUrl);
  if (ws != null) {
    let heartbeatInterval: number | undefined;

    const startHeartbeat = (): void => {
      heartbeatInterval = window.setInterval(() => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send('ping'); // 发送心跳包
          console.log('全局WS发送心跳包: ping');
        }
      }, 1000 * 60); // 每1-发送一次心跳包
    };

    const stopHeartbeat = (): void => {
      if (heartbeatInterval) {
        clearInterval(heartbeatInterval);
        heartbeatInterval = undefined;
        console.log('全局WS心跳包关闭');
      }
    };

    ws.onopen = function (): void {
      startHeartbeat(); // 连接成功后开始心跳包
    };

    ws.onmessage = function (e): void {
      const message = e.data;
      if (message === 'pong') {
        console.log('全局WS收到心跳回复: pong');
        return;
      }
      const json = JSON.parse(message);
      if (updateWsLinkType != null) {
        updateWsLinkType(WsLinkType.MESSAGE, json?.data);
      }
    };

    ws.onerror = function (err): void {
      console.log('ws onmerror', err);
      stopHeartbeat(); // 发生错误时停止心跳包
      if (updateWsLinkType != null) {
        updateWsLinkType(WsLinkType.ERROR, undefined);
      }
    };

    ws.onclose = function (e): void {
      console.log('ws onclose', e);
      stopHeartbeat(); // 连接关闭时停止心跳包
      if (updateWsLinkType != null) {
        updateWsLinkType(WsLinkType.CLOSE, undefined);
      }
    };
  }
  return ws;
}
